import React from 'react';

const ItalySVG = () => {

  const css = `.st2-25{fill:url(#SVGID_1_25);}
.st3-25{fill:url(#SVGID_2_25);}
.st4-25{fill:url(#SVGID_3_25);}
.st5-25{fill:url(#SVGID_4_25);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M669.8,726.4l2.8-1.4l2-0.3l3.1,1.1l3.7,2.4l4.2,1.2l4.1,0.3l3.8-0.4l5.3-2.2l4.6-0.3l6.6,0.6l6,1.9l4.8,2.7
							l2.4,2.6l1.1,3l-0.1,2.1l-2.3,3.8l-2.3,3.3l-9.7,12.2l-9.7,13.9l-3.3,3.6l-2.2,1.9l-3.8,1.9l-3.8,0.3l-4-0.2l-8,0.1l-6,0.8l-7.6,2.4
							l-3.3,0.4l-2.3-1.1l-2.4-1.1l-1-4.2c0,0,0.9-3.8,1-4.1s2.9-5.3,2.9-5.3s3.9-5.6,4.6-6.6s5.6-5.9,5.7-6.2c0.1-0.3,2.8-4.7,2.8-4.7
							l0.7-3.7l-1.2-5.4l-1.3-7.4l-0.1-3.8l1.1-2.3L669.8,726.4z"/>
        <path className="st0" d="M784.8,840l6.7-0.4l4.4,0.4l3.7,1l3.2,2.2l5.4,3.3l4.9,2.3l5.6,1.4l8,1.2l9.4,1l13.4,1.3l9.7,0.8l8,1.7l3.9,2
							l1.2,1.4l0.4,1.4l-1.2,1.3l-3.8,1.3l-7,3.2l-9.1,5.1l-4.8,4.3l-2.4,4.6l-1.8,4.9l-2,4.4l-2,2.8l-3.7,2.4l-3.6,0.8l-4.8-0.8l-2.6-1.6
							c0,0-2.7-2.6-2.9-2.9s-3-4.4-3-4.4l-5.1-7.8l-5-4.7l-6-3.9l-7.4-3.7l-9.3-5.2l-6.7-3.3l-5-4.8l-3.8-3.2l-1.6-4l1.6-3l1.7-1.2
							l4.3-1.7l5.4-0.4L784.8,840z"/>
        <path className="st0" d="M713.7,646.3l-8-0.3l-2.5-0.3l-2.7-1.3l2.3-0.7l3.3-2.4l4.4-2.8l1.7-2.2l-2-1.8l-10.2-2.2l-3.2-1.1l-2.2-1.9
							l-2.2-2.7v-2l1.6-3.3l7-3.8l0.2-2.4l-5.3-6.7l-1.1-3.2l1.1-2.2l2.2-1l2.9,1h5.3l4.9-0.9l3.8-1.7l2.3-2.2v-1.6l-1.3-2.1l-0.6-1.4
							l0.3-2.2l5.1-6l1.8,1.3l3.5,1.6l4.2,0.9l5.3,1l5.8,0.9l5.3,0.5l3.1,0.1c0,0,3-0.4,3.3-0.5c0.2-0.1,4.6-2.3,4.7-2.5
							c0.1-0.2,6.3-4.1,6.3-4.1l5.8-3.2l2.5-1.2l1.6,0.2l1.4,1.4l1.9,4.3l2.7,7.5l2.4,5l1.7,2.1l1.6,0.8l3-0.7l2.1-1.8l3.3-4.4l2.6-3
							l2-1.6l2-0.7l3.3-0.1l4.2,1.3l4.4,2.4l3.2,1.3l3,0.7l3.2-0.1l1.8-0.6l1.9-1.9l1.7-1.8l1.6-1l1.1-0.1l1.9,0.8l1.9,1.3l2.1,1.2l2,0.1
							l2.5-0.8l1.4-1l2.3-2.3l1.5-0.9l2.6-0.6l2.4-0.2l2.2-0.7h1.2l3.5,3.1l3.1,1.6c0,0,5.7-0.2,5.9-0.4s9-1.2,9-1.2l6.5-1.3l16.1,2.4
							l6.7,1.8l1.2,2.5l-0.1,4.9l1.8,3l3.7,1.9l7.6,3l3.7,0.4l-7.1,9.2l-11,17.9l-5.9,0.7l-5.6,0.8l-3.7,0.9l-3,1.6l-1.9,1.4l-2,1.2
							l-0.6,2.2l0.4,2.2l0.1,2l-1,2.1l-3.4,2.3l-5.4,4l-2.4,2.1l-1.3,2.3l-0.3,2.4l0.1,2.1l0.8,2.9l1.7,2.7l3.5,3.4l7.6,5.9l5.6,5l6.4,6.1
							l2.2,3.7l1.4,4.7l0.7,4.1l-0.4,4.7l-0.6,6.5l0.2,4.7l0.4,3.9l1.3,5.3l2.8,5l4.5,6.5l3.9,4l4.9,4.9l4.4,2.7l5.1,2.7l5.2,1.4l4.4,0.5
							c0,0,4.7,1.4,4.9,1.6c0.2,0.1,2.7,2,2.7,2l1.3,2.7l0.1,2.9l0.1,2.7l1.2,2.7l3.1,3.5l7,4.6l14.3,10.7l15.8,12.6l9.3,8.4l3.6,4.1
							l2.1,3.6l0.3,3.7l-0.7,2.8l-1.3,2l-3,3l-1.6,0.6l-2.1-0.1l-2.5-1.9l-5.9-4L974,816l-4.5-2.3l-4.4-2.1l-3.3-1.8l-3.2-0.4l-4.8-0.1
							l-4.5,0.1l-4.4,0.3l-1.6,0.5l-2.6,1.4l-1.8,1.5l-1.7,1.6l-1.4,1.9l-1.1,2.5l-0.4,2l0.7,1.9l1.2,2.5l4.8,6.3l3.3,3.7l0.2,2.1
							c0,0-0.7,1.5-0.7,1.7c-0.1,0.2-1,1.3-1.3,1.6s-3.3,1.8-3.3,1.8l-3.5,1.2l-3.9,0.7l-5.9,1.4l-4,0.5l-3.3,0.7l-1.9,0.8l-2.7,1.3
							l-2.7,3l-4.1,3.9l-5,3.9l-6.5,4.1l-4.7,2.4l-3.3,0.6l-2.1,0.1h-1.7l-1.2-1.6c0,0-0.5-1.6-0.5-2.1s0.1-3.9,0.1-3.9s1.6-2.9,1.9-3.3
							c0.3-0.4,2.8-3.9,3.2-4.1s2.6-2.8,2.6-2.8l3.8-2.1l4.7-2.7c0,0,3.6-2.7,3.8-3.2s3.2-4.3,3.2-4.5s1.3-3.4,1.3-3.4l0.5-3.2l-0.5-5.9
							c0,0-0.6-5-0.6-5.3s0.2-4.5,0.2-4.5l1-2.7c0,0,1.2-2.4,1.1-2.7c-0.1-0.3-0.4-1.7-0.4-1.9s-0.5-1.1-0.5-1.1l-3.1-1.9l-4.4-1.7
							l-2.8-2.2c0,0-0.6-1.5-0.7-1.7c-0.1-0.2-1-2.7-1-2.7l-0.8-2.5l-1.9-3l-2-2.8l-2.1-1.9l-2.9-1.6l-6-4l-6.1-4.1l-3.7-3.8l-2.6-4.1
							l-3.1-3.9l-1.6-2.7l-3-2.3l-3.3-2.1l-5.6-2.7l-7.1-3.3l-4.9-2.6l-3.7-2.2c0,0-3.1-3.9-3.7-4.8c-0.6-0.9-5.5-7.7-5.5-7.7l-5.4-8.4
							l-6-7.2l-5.3-5.3l-4.8-5.3c0,0-4.1-4.1-4.3-4.4c-0.1-0.2-2.8-4.3-2.8-4.3l-2.6-3.9c0,0-2.1-3.8-2.2-4c-0.1-0.2-1-3.2-1-3.4
							c-0.1-0.2-1-5-1-5l-0.1-3.9l0.4-3.4l1-4c0,0-0.1-2.2-0.1-2.6c0-0.4-1.6-5.4-1.6-5.4l-1.4-3.8l-3-4.1l-4.1-4l-3.6-3.5l-5.4-3.3
							l-4.7-2.4l-5.7-2.1l-4.4-1.3l-4.6-0.7l-4,0.2l-5.6-0.1l-4.2,0.7l-3,0.9l-3.5,1.9l-3.7,1.7l-4.8,2.1l-3.8,0.6L713.7,646.3z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="922.2" cy="787.8" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_25" gradientUnits="userSpaceOnUse" x1="2219.72" y1="-505.4862" x2="2263.0229" y2="-505.4862" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-25" d="M901.1,787.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C900.2,783.4,899.8,787.1,901.1,787.8z"/>

          <linearGradient id="SVGID_2_25" gradientUnits="userSpaceOnUse" x1="2241.0107" y1="-516.3104" x2="2241.3413" y2="-472.7037" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-25" d="M901.1,787.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C900.2,783.4,899.8,787.1,901.1,787.8z"/>

          <linearGradient id="SVGID_3_25" gradientUnits="userSpaceOnUse" x1="2219.72" y1="-472.6424" x2="2263.0579" y2="-472.6424" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-25" d="M901.1,787.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C900.2,783.4,899.8,787.1,901.1,787.8z"/>

          <radialGradient id="SVGID_4_25" cx="2241.2607" cy="-524.8727" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-25" cx="921.6" cy="729.1" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M929.4,707.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C944.6,700.6,937.8,707.4,929.4,707.4z"/>
          <path className="st7" d="M929.4,705.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C944.6,698.6,937.8,705.4,929.4,705.4z"/>
          <path className="st7" d="M928.1,706.2l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C929.7,701.9,929.7,704.6,928.1,706.2z"/>
          <path className="st6" d="M922.9,697.3l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7H921c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3c0.3,0.3,0.7,0.5,1.2,0.5
								C922.2,697.8,922.6,697.6,922.9,697.3z"/>
        </g>
      </g>
    </>
  );
};

export default ItalySVG;
