import React from 'react';

const PortugalSVG = () => {

  const css = `.st2-22{fill:url(#SVGID_1_22);}
.st3-22{fill:url(#SVGID_2_22);}
.st4-22{fill:url(#SVGID_3_22);}
.st5-22{fill:url(#SVGID_4_22);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M153.5,572.2l-4.8,5.5l-4.4,4.9l-1.9,2.6l-1.4,3.1l-3.8,4.7l-8,7.7l-6.4,5.4l-6.6,4.4l-9.4,6.6l-9.1,5.6
							L86,629.8c0,0-3.7,1-4.2,1.4c-0.6,0.4-5.7,1.4-5.7,1.4l-4,1.3l-2.6,1.9l-2.1,2.1l-0.7,1.9l0.8,2.3l1.7,2.2l2.8,3.6l2.2,3.6l0.8,3.2
							l-0.2,4.3l-1.4,4.6l-2.7,3.2l-7.2,8l-5.9,5.2c0,0-5.6,5.7-5.9,5.9c-0.3,0.2-4,3.4-4,3.4l-1.4,2.4v2.2l1.4,1.2l3,1.2l7,1.7l8.1,1.4
							l9.4,1.3l11.2,1.3l7.7,0.5l-0.3-3.6l1.3-5.2l3.7-3.9l4.4-3.4l4.3-1.9l3.7-1l5.6,0.4l5.9,0.3l2.5-1.6l1.5-1.9l-1-3.1l-3.7-5.5
							l-0.1-2.4l2.5-3.1l6.7-4l5-1.6h2.4l2.2,0.9l2.8-0.6l0.3-3.1l-1.3-5.8l-2.2-5l-0.7-3l1.7-3.4l2.5-1.2l14.2,2.4l1.9-1.2l6.1-8.7
							l4.3-3.1l7-2.1l3.1-3l3.7-6.2l1.3-5.5l3-5l3.1-1.9l18.1-2.5l8.7-1.9l3.1-1.5l0.4-2.2l-1.6-1.9l-4.1-2.5l0.3-1.8l1.3-1.6l-0.9-3
							l-2.1-1.2l-4.9-0.6l-2.8-1.3l-4.7,0.3l-4.4,0.6l-4.4,1.5l-1.9-2.1l-1.3-0.9l-5-1.2l-5.8-1l-5.5-2.1l-1.8-1.6l-0.4-1.9l1.3-2.1
							l-1.3-2.5l-1.9-0.7l-4.7,0.1l-4.6,2.2l-3.6,0.6L153.5,572.2z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="164.3" cy="601" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_22" gradientUnits="userSpaceOnUse" x1="2976.8152" y1="-695.3688" x2="3020.1182" y2="-695.3688" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-22" d="M143.2,601c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C142.3,596.6,141.9,600.3,143.2,601z"/>

          <linearGradient id="SVGID_2_22" gradientUnits="userSpaceOnUse" x1="2998.106" y1="-706.1931" x2="2998.4365" y2="-662.5864" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-22" d="M143.2,601c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C142.3,596.6,141.9,600.3,143.2,601z"/>

          <linearGradient id="SVGID_3_22" gradientUnits="userSpaceOnUse" x1="2976.8152" y1="-662.525" x2="3020.1531" y2="-662.525" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-22" d="M143.2,601c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C142.3,596.6,141.9,600.3,143.2,601z"/>

          <radialGradient id="SVGID_4_22" cx="2998.356" cy="-714.7552" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-22" cx="163.7" cy="542.3" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M171.5,520.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C186.7,513.8,179.9,520.6,171.5,520.6z"/>
          <path className="st7" d="M171.5,518.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C186.7,511.8,179.9,518.6,171.5,518.6z"/>
          <path className="st7" d="M170.2,519.4l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C171.8,515.1,171.8,517.8,170.2,519.4z"/>
          <path className="st6" d="M165,510.5l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C164.3,511,164.7,510.8,165,510.5z"/>
        </g>
      </g>
    </>
  );
};

export default PortugalSVG;
