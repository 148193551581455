import React, { useState } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import IconClose from "../assets/img/icons/icon_close.svg";

// custom hooks
import useGame from "../hooks/useGame";

const Question = () => {
  // wait
  async function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const {
    REACT_APP_API_URL,
    REACT_APP_ANIMATION_TIME,
    REACT_APP_ENVIRONMENT,
  } = process.env;

  const {
    activeCountry,
    nextQuestion,
    showQuestion,
    previousQuestion,
    hideQuestionPage,
    showQuestionActive,
  } = useGame();

  const [isWrong, setIsWrong] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const [wasCorrectAnswer, setWasCorrectAnswer] = useState(false);

  const question = activeCountry.questions[activeCountry.questionIndex];
  const showText = question.text_or_picture === "text";
  const API_URL =
    REACT_APP_ENVIRONMENT === "production"
      ? `https://${window.location.host}/wp/?rest_route=/game`
      : REACT_APP_API_URL;
  // const API_URL = REACT_APP_API_URL;

  //const en = useIntl().locale === 'en';
  const ru = useIntl().locale === "ru";

  const checkAnswer = async (answer) => {
    if (hasClicked) {
      return;
    }
    setHasClicked(true);
    const payload = {
      country_id: activeCountry.ID,
      question_id: question.ID,
      answer_id: answer,
    };

    const response = await axios.post(`${API_URL}/check_answer`, payload);
    setHasAnswered(true);
    setWasCorrectAnswer(response.data.correct_id);
    if (response.data.is_correct) {
      await correctAnswer(answer);
    } else {
      await wrongAnswer(answer);
    }
    setHasClicked(false);
  };

  const correctAnswer = async (answer) => {
    setIsCorrect(answer);
    await wait(REACT_APP_ANIMATION_TIME);
    setHasAnswered(false);
    setWasCorrectAnswer(null);
    setIsCorrect(false);
    nextQuestion();
  };

  const wrongAnswer = async (answer) => {
    setIsWrong(answer);
    await wait(REACT_APP_ANIMATION_TIME);
    setHasAnswered(false);
    setWasCorrectAnswer(false);
    setIsWrong(false);
    previousQuestion();
  };

  const renderCategory = () => {
    switch (question.category) {
      case "history":
        return "Suurmees / Ajalugu";
      case "landscape":
        return "Maamärgid / Arhitektuur";
      case "culture":
        return "Kultuur";
      case "food":
        return "Toit / Jook";
      case "literature":
        return "Kirjandus";
      default:
        return question.category;
    }
  };

  // if the question is not visible, don't render anything
  if (!showQuestion) {
    return "";
  }
  // render output
  return (
    <div
      className={classNames({
        set: true,
        active: showQuestionActive,
      })}
    >
      <div className="flex-container">
        <div className="question-container">
          <div className="container">
            <div className="flex-container">
              <div className="question">
                <span className="topic" />
                <h1 className="question-title">
                  {ru
                    ? activeCountry.ru.questions[question.i].question
                    : question.question}
                </h1>
                {question.picture && (
                  <img src={question.picture} alt="Question" />
                )}
                {question.audio_file && (
                  <audio controls style={{ marginTop: "30px" }}>
                    <source src={question.audio_file} type="audio/mpeg" />
                  </audio>
                )}
              </div>

              <div className="choose">
                <div className="wrap">
                  <span
                    data-id={question.answers[0].ID}
                    className={classNames({
                      btn: true,
                      choice: true,
                      correct:
                        isCorrect === question.answers[0].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer === question.answers[0].ID),
                      "not-correct":
                        isWrong === question.answers[0].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer !== question.answers[0].ID),
                    })}
                    onClick={() => checkAnswer(question.answers[0].ID)}
                  >
                    <span className="a">A</span>
                  </span>
                  {showText ? (
                    <span className="text">
                      {ru
                        ? activeCountry.ru.questions[question.i].answers[0]
                            .answer_text
                        : question.answers[0].answer_text}
                    </span>
                  ) : (
                    <img src={question.answers[0].answer_image} alt="" />
                  )}
                </div>
                <div className="wrap">
                  <span
                    data-id={question.answers[1].ID}
                    className={classNames({
                      btn: true,
                      choice: true,
                      correct:
                        isCorrect === question.answers[1].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer === question.answers[1].ID),
                      "not-correct":
                        isWrong === question.answers[1].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer !== question.answers[1].ID),
                    })}
                    onClick={() => checkAnswer(question.answers[1].ID)}
                  >
                    <span className="b">B</span>
                  </span>
                  {showText ? (
                    <span className="text">
                      {ru
                        ? activeCountry.ru.questions[question.i].answers[1]
                            .answer_text
                        : question.answers[1].answer_text}
                    </span>
                  ) : (
                    <img src={question.answers[1].answer_image} alt="" />
                  )}
                </div>
                <div className="wrap">
                  <span
                    data-id={question.answers[2].ID}
                    className={classNames({
                      btn: true,
                      choice: true,
                      correct:
                        isCorrect === question.answers[2].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer === question.answers[2].ID),
                      "not-correct":
                        isWrong === question.answers[2].ID ||
                        (hasAnswered &&
                          wasCorrectAnswer !== question.answers[2].ID),
                    })}
                    onClick={() => checkAnswer(question.answers[2].ID)}
                  >
                    <span className="c">C</span>
                  </span>
                  {showText ? (
                    <span className="text">
                      {ru
                        ? activeCountry.ru.questions[question.i].answers[2]
                            .answer_text
                        : question.answers[2].answer_text}
                    </span>
                  ) : (
                    <img src={question.answers[2].answer_image} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="trivia-container">
          <div className="container">
            <div className="flex-container">
              <div className="flag-wrap">
                <div className="flag-img">
                  <img
                    src={activeCountry.flag}
                    className="medium"
                    alt={activeCountry.name}
                  />
                </div>
                <h3>{ru ? activeCountry.ru.title : activeCountry.title}</h3>
              </div>

              <div className="facts">
                <ul>
                  <li>
                    <span className="label">
                      <FormattedMessage id="area" defaultMessage="Pindala:" />
                    </span>
                    <NumberFormat
                      value={activeCountry.area}
                      displayType={"text"}
                      thousandSeparator={" "}
                      renderText={(value) => (
                        <span className="value">
                          {value} km<sup>2</sup>
                        </span>
                      )}
                    />
                  </li>
                  <li>
                    <span className="label">
                      <FormattedMessage
                        id="population"
                        defaultMessage="Elanike arv:"
                      />
                    </span>
                    <NumberFormat
                      value={activeCountry.population}
                      displayType={"text"}
                      thousandSeparator={" "}
                      renderText={(value) => (
                        <span className="value">{value}</span>
                      )}
                    />
                  </li>
                  <li>
                    <span className="label">
                      <FormattedMessage
                        id="joinedEU"
                        defaultMessage="Ühines Euroopa Liiduga:"
                      />
                    </span>
                    <span className="value">{activeCountry.eu_year}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span className="label">
                      <FormattedMessage
                        id="capital"
                        defaultMessage="Pealinn:"
                      />
                    </span>
                    <span className="value">
                      {ru ? activeCountry.ru.capital : activeCountry.capital}
                    </span>
                  </li>
                  <li>
                    <span className="label">
                      <FormattedMessage id="language" defaultMessage="Keel:" />
                    </span>
                    <span className="value">
                      {ru
                        ? activeCountry.ru.language
                        : activeCountry.language.toLowerCase()}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="close">
        <span onClick={hideQuestionPage}>
          <FormattedMessage id="close" defaultMessage="Sulge" />
        </span>
        <img
          src={IconClose}
          className="close-icon"
          onClick={hideQuestionPage}
          alt="Close"
        />
      </div>
    </div>
  );
};

export default Question;
