import React from 'react';

const AustriaSVG = () => {

  const css = `.st2-21{fill:url(#SVGID_1_21);}
.st3-21{fill:url(#SVGID_2_21);}
.st4-21{fill:url(#SVGID_3_21);}
.st5-21{fill:url(#SVGID_4_21);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M834.4,561.4l4,0.9l2.9,2.4l3.6,3.7l2.7,1.5l3-0.3l2.4-1.3l4-1.3l4,0.4l6.2,1.5l4.7,2.8l3.9,2.2l5.3,1.3
							l5.3-0.3l4.4-0.9l6.4-0.7l6.7-0.1l8,1l11.9,2.2l5.8,3.3l4.9,2.2l3.4-0.1l3.4-1.9l1.3-3.4l0.7-3.9l2.1-4l3-1.5l5.2-0.7l7.6-0.9
							l2.4-1.2l4.7-1.8l6.5-0.4h8.9h2.7l4.3,1.6l5.2,2.2l5.9,0.9h5.6l3.9-0.1l3-1.2l3.9-2.8l3.7-3l1.9-1.2h2.5l8,3.1l6.7,2.4l5.5,2.5
							l3.7,4l4.1,3.7l4,2.5l7.3,0.4l3.7-0.3l3.9,0.3l1.3,1.2l-0.4,2.7l0.6,6.2l1.8,7l1.5,2.8l-1.3,1l-1.9,0.3l-2.8,2.5l-2.4,2.7l-2.5,2.1
							l-2.2,0.9l-4.3,0.1l-3.6-0.9l-3.4-1.3l-2.4-1.3l-1.9,1.8l-0.4,2.4l-1.6,2.5l-3.4,2.2c0,0-1.6,1.5-2.2,2.1s-2.4,4-2.4,4l-1.8,0.9
							c0,0-2.4,0.4-3.9,0.3c-1.5-0.1-3.6,1-3.6,1l-1.9,2.1c0,0-3.1,0.4-3.6,0.4c-0.4,0-9.8,1-9.8,1l-6.1-0.1l-8.4-0.9l-6.4-1.2l-11.1,0.6
							l-7.3,1.2l-4.4-0.1l-4.6-0.9l-4.4-2.2l-8.4-3.4l-13.5-4.4l-13.6-4.3l-7-1.2l-7.6-3l-3.7-1.9l-1.8-3l0.1-4.9l-1.2-2.5l-6.7-1.8
							l-10.1-1.5l-6.1-0.9l-6.5,1.3l-9,1.2l-5.9,0.4l-3.1-1.6l-4.6-4.1l-6.5-3.1l-4.4-0.9l-6.7-0.1c0,0-3.4-1.5-3.9-1.5s-4.1-3.4-4.1-3.4
							l-1.8-3.3l1-4.1l2.2-3.3l2.7-2.8L834.4,561.4z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="936.5" cy="595.5" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_21" gradientUnits="userSpaceOnUse" x1="2204.6355" y1="-697.6836" x2="2247.9385" y2="-697.6836" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-21" d="M915.4,595.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C914.5,591.1,914.1,594.8,915.4,595.5z"/>

          <linearGradient id="SVGID_2_21" gradientUnits="userSpaceOnUse" x1="2225.926" y1="-708.5078" x2="2226.2568" y2="-664.9011" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-21" d="M915.4,595.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C914.5,591.1,914.1,594.8,915.4,595.5z"/>

          <linearGradient id="SVGID_3_21" gradientUnits="userSpaceOnUse" x1="2204.6355" y1="-664.8398" x2="2247.9734" y2="-664.8398" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-21" d="M915.4,595.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C914.5,591.1,914.1,594.8,915.4,595.5z"/>

          <radialGradient id="SVGID_4_21" cx="2226.1763" cy="-717.0701" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-21" cx="935.9" cy="536.8" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M943.7,515.1h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C958.9,508.3,952.1,515.1,943.7,515.1z"/>
          <path className="st7" d="M943.7,513.1h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C958.9,506.3,952.1,513.1,943.7,513.1z"/>
          <path className="st7" d="M942.4,513.9l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C944,509.6,944,512.3,942.4,513.9z"/>
          <path className="st6" d="M937.2,505l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6V476c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3c0.3,0.3,0.7,0.5,1.2,0.5
								C936.5,505.5,936.9,505.3,937.2,505z"/>
        </g>
      </g>
    </>
  );
};

export default AustriaSVG;
