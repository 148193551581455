import Austria from '../assets/img/flags/Austria.svg';
import AustriaSVG from '../countrypaths/AustriaSVG';
import Belgium from '../assets/img/flags/Belgium.svg';
import BelgiumSVG from '../countrypaths/BelgiumSVG';
import Bulgaria from '../assets/img/flags/Bulgaria.svg';
import BulgariaSVG from '../countrypaths/BulgariaSVG';
import Croatia from '../assets/img/flags/Croatia.svg';
import CroatiaSVG from '../countrypaths/CroatiaSVG';
import Cyprus from '../assets/img/flags/Cyprus.svg';
import CyprusSVG from '../countrypaths/CyprusSVG';
import Czech from '../assets/img/flags/Czech.svg';
import CzechSVG from '../countrypaths/CzechSVG';
import Denmark from '../assets/img/flags/Denmark.svg';
import DenmarkSVG from '../countrypaths/DenmarkSVG';
import Estonia from '../assets/img/flags/Estonia.svg';
import EstoniaSVG from '../countrypaths/EstoniaSVG';
import Finland from '../assets/img/flags/Finland.svg';
import FinlandSVG from '../countrypaths/FinlandSVG';
import France from '../assets/img/flags/France.svg';
import FranceSVG from '../countrypaths/FranceSVG';
import Germany from '../assets/img/flags/Germany.svg';
import GermanySVG from '../countrypaths/GermanySVG';
import Greece from '../assets/img/flags/Greece.svg';
import GreeceSVG from '../countrypaths/GreeceSVG';
import Hungary from '../assets/img/flags/Hungary.svg';
import HungarySVG from '../countrypaths/HungarySVG';
import Ireland from '../assets/img/flags/Ireland.svg';
import IrelandSVG from '../countrypaths/IrelandSVG';
import Italy from '../assets/img/flags/Italy.svg';
import ItalySVG from '../countrypaths/ItalySVG';
import Latvia from '../assets/img/flags/Latvia.svg';
import LatviaSVG from '../countrypaths/LatviaSVG';
import Lithuania from '../assets/img/flags/Lithuania.svg';
import LithuaniaSVG from '../countrypaths/LithuaniaSVG';
import Luxembourg from '../assets/img/flags/Luxembourg.svg';
import LuxembourgSVG from '../countrypaths/LuxembourgSVG';
import Malta from '../assets/img/flags/Malta.svg';
import MaltaSVG from '../countrypaths/MaltaSVG';
import Netherlands from '../assets/img/flags/Netherlands.svg';
import NetherlandsSVG from '../countrypaths/NetherlandsSVG';
import Poland from '../assets/img/flags/Poland.svg';
import PolandSVG from '../countrypaths/PolandSVG';
import Portugal from '../assets/img/flags/Portugal.svg';
import PortugalSVG from '../countrypaths/PortugalSVG';
import Romania from '../assets/img/flags/Romania.svg';
import RomaniaSVG from '../countrypaths/RomaniaSVG';
import Slovakia from '../assets/img/flags/Slovakia.svg';
import SlovakiaSVG from '../countrypaths/SlovakiaSVG';
import Slovenia from '../assets/img/flags/Slovenia.svg';
import SloveniaSVG from '../countrypaths/SloveniaSVG';
import Spain from '../assets/img/flags/Spain.svg';
import SpainSVG from '../countrypaths/SpainSVG';
import Sweden from '../assets/img/flags/Sweden.svg';
import SwedenSVG from '../countrypaths/SwedenSVG';

const paths = {
  11: {
    name: 'austria',
    flag: Austria,
    render: AustriaSVG(),
  },
  13: {
    name: 'belgium',
    flag: Belgium,
    render: BelgiumSVG(),
  },
  15: {
    name: 'bulgaria',
    flag: Bulgaria,
    render: BulgariaSVG(),
  },
  17: {
    name: 'estonia',
    flag: Estonia,
    render: EstoniaSVG(),
  },
  19: {
    name: 'spain',
    flag: Spain,
    render: SpainSVG(),
  },
  24: {
    name: 'croatia',
    flag: Croatia,
    render: CroatiaSVG(),
  },
  26: {
    name: 'ireland',
    flag: Ireland,
    render: IrelandSVG(),
  },
  28: {
    name: 'italy',
    flag: Italy,
    render: ItalySVG(),
  },
  30: {
    name: 'greece',
    flag: Greece,
    render: GreeceSVG(),
  },
  32: {
    name: 'cyprus',
    flag: Cyprus,
    render: CyprusSVG(),
  },
  38: {
    name: 'latvia',
    flag: Latvia,
    render: LatviaSVG(),
  },
  34: {
    name: 'lithuania',
    flag: Lithuania,
    render: LithuaniaSVG(),
  },
  36: {
    name: 'luxembourg',
    flag: Luxembourg,
    render: LuxembourgSVG(),
  },
  22: {
    name: 'netherlands',
    flag: Netherlands,
    render: NetherlandsSVG(),
  },
  40: {
    name: 'malta',
    flag: Malta,
    render: MaltaSVG(),
  },
  42: {
    name: 'poland',
    flag: Poland,
    render: PolandSVG(),
  },
  44: {
    name: 'portugal',
    flag: Portugal,
    render: PortugalSVG(),
  },
  46: {
    name: 'france',
    flag: France,
    render: FranceSVG(),
  },
  48: {
    name: 'sweden',
    flag: Sweden,
    render: SwedenSVG(),
  },
  50: {
    name: 'romania',
    flag: Romania,
    render: RomaniaSVG(),
  },
  52: {
    name: 'germany',
    flag: Germany,
    render: GermanySVG(),
  },
  54: {
    name: 'slovakia',
    flag: Slovakia,
    render: SlovakiaSVG(),
  },
  56: {
    name: 'slovenia',
    flag: Slovenia,
    render: SloveniaSVG(),
  },
  58: {
    name: 'finland',
    flag: Finland,
    render: FinlandSVG(),
  },
  60: {
    name: 'denmark',
    flag: Denmark,
    render: DenmarkSVG(),
  },
  62: {
    name: 'czech',
    flag: Czech,
    render: CzechSVG(),
  },
  64: {
    name: 'hungary',
    flag: Hungary,
    render: HungarySVG(),
  }
};

export default paths;
