import React from 'react';

const CroatiaSVG = () => {

  const css = `.st2-17{fill:url(#SVGID_1_17);}
.st3-17{fill:url(#SVGID_2_17);}
.st4-17{fill:url(#SVGID_3_17);}
.st5-17{fill:url(#SVGID_4_17);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M929.4,655.2l-2-1.6l-0.7-2.4l1.3-3.4l2.1-1.9l2-0.1l6.4,0.9l6.9,0.1l8.3,0.1l4,0.8l7.4,3.8l4.4,2.2l2.3,0.1
							l3.8-2.7l7.2-4.6l3.6-0.8l2.8,0.2l3.7,0.3l1.9-1.8l5.7-6l2.1-1l3.1-0.3l4.9-0.3l5.3-1l8.4-2l5.1-0.9l2.4,2.3l5.3,4.3l5.9,4.9l4.8,5
							l2,3.1l3.6,3.8l4.6,2.8l7.9,3.2l5.4,1.6l7.9,0.9l11.2,0.6l3.6,0.3l-0.9,3.8l-0.3,4.3l0.2,2.8c0,0,1.4,1.6,1.6,1.9
							c0.1,0.3,3.6,3.2,3.6,3.2l2.4,2.2l0.8,2.1l-0.8,2.8l-0.8,3.3l-0.2,4.1l-0.2,4.7l-0.6,2.4l-1.4-0.1l-4.1-4.1l-8.8-6l-5.9-3.6
							l-8.4-3.1l-8.3-2.8l-10.6-3.1l-12.6-3.6l-7.4-2.1l-4.4-2.4l-4-2.1l-2.9-1l-3,0.2l-5.9,1.2l-5.2,0.9l-3.4-0.3l-2.4-1.7l-2.8-2.2
							l-2.7-1.4l-3.6-0.3l-2.3,0.6l-3.3,1.6l-2.8,2.2l-1,2l0.2,2.3l1.6,2.8l5.4,6.2l0.9,2.4l0.1,1.9l-1.1,2.1l-0.8,2.3l0.2,2l2.3,3.3
							l6.9,9.1l7.1,8.8l0.1,1.6l0.2,3.4l1.8,2.2l8.3,11.4l4.9,7.2l3.1,4.7h-1.1l-5.2-4.2l-4.7-2.8l-6-2.2l-5-1.9l-3.7-1.6l-4.8-2.7
							l-2.8-2.2l-2.4-3.2l-0.7-2.9l-0.1-1.9l-1.8-2l-5.1-2.8l-6.1-2.9l-3.9-2.1l-3.6-2.8l-3.9-5.6l-5.4-11l-4.3-10.8l-1.1-5l-0.1-3.6
							l0.6-3.4l0.6-2.1l-0.4-2.3l-2.1-2.1l-4.2-2l-5.2-1.9l-6.1-1.4l-3.8-1.1L929.4,655.2z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1026.8" cy="658.7" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_17" gradientUnits="userSpaceOnUse" x1="2114.5256" y1="-634.1659" x2="2157.8286" y2="-634.1659" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-17" d="M1005.7,658.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1004.8,654.3,1004.4,658,1005.7,658.7z"/>

          <linearGradient id="SVGID_2_17" gradientUnits="userSpaceOnUse" x1="2135.8162" y1="-644.9901" x2="2136.147" y2="-601.3834" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-17" d="M1005.7,658.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1004.8,654.3,1004.4,658,1005.7,658.7z"/>

          <linearGradient id="SVGID_3_17" gradientUnits="userSpaceOnUse" x1="2114.5256" y1="-601.3221" x2="2157.8635" y2="-601.3221" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-17" d="M1005.7,658.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1004.8,654.3,1004.4,658,1005.7,658.7z"/>

          <radialGradient id="SVGID_4_17" cx="2136.0664" cy="-653.5524" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-17" cx="1026.2" cy="600" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1034,578.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1049.2,571.5,1042.4,578.3,1034,578.3z"/>
          <path className="st7" d="M1034,576.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1049.2,569.5,1042.4,576.3,1034,576.3z"/>
          <path className="st7" d="M1032.7,577.1l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1034.3,572.8,1034.3,575.5,1032.7,577.1z"/>
          <path className="st6" d="M1027.5,568.2l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1026.8,568.7,1027.2,568.5,1027.5,568.2z"/>
        </g>
      </g>
    </>
  );
};

export default CroatiaSVG;
