import React from 'react';

const HungarySVG = () => {

  const css = `.st2-19{fill:url(#SVGID_1_19);}
.st3-19{fill:url(#SVGID_2_19);}
.st4-19{fill:url(#SVGID_3_19);}
.st5-19{fill:url(#SVGID_4_19);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <polygon className="st0" points="1094.2,665.8 1090.7,665.4 1079.4,664.9 1071.6,664 1066.1,662.4 1058.2,659.2 1053.7,656.4
							1049.8,652.2 1048.1,649.6 1043.3,644.6 1037.4,639.7 1032.1,635.3 1029.7,633 1029.4,630.1 1028.3,626.3 1027.3,624.8
							1026.6,622.4 1027.7,622.4 1029.6,620.3 1033.2,619.3 1037,619 1038.8,618.1 1041.2,614.1 1043.4,612 1046.8,609.8 1048.4,607.3
							1048.9,604.9 1050.8,603.1 1056.6,605.8 1060.1,606.7 1064.4,606.6 1066.7,605.7 1069.2,603.6 1074.4,598.4 1076.3,598.1
							1077.6,597.1 1079.4,600.5 1084,604.9 1089.9,608.6 1096.6,611 1103.4,612.3 1115,613.1 1123.1,612.9 1127,609.8 1129.9,609.4
							1135,609.2 1147.3,610.3 1155.6,611.6 1163.7,612.5 1169.6,612.3 1194.5,607.1 1198.4,606.6 1201.5,607.9 1211.4,610.9
							1224.1,616.6 1227.6,618.9 1229.8,621.4 1233.3,623.9 1236.6,625.2 1241,626.6 1247.7,629.8 1252.4,633.2 1256.4,635.4
							1257.9,636.8 1257.9,638.4 1256.3,640.5 1254.2,640.8 1248.7,641.8 1239.3,643.6 1233.9,644.5 1228.1,645.1 1225.2,645.5
							1222.1,647 1215.9,650.7 1207.7,655.1 1197.5,661.4 1185.9,668.2 1180.9,670.4 1176.6,672 1169.8,673.1 1163.1,674 1155.7,674.6
							1152.6,675.6 1149.2,673.1 1143.3,670.6 1137.6,667.7 1135.6,666.4 1131,666 1124.3,665.5 1113.2,665.5 1098.8,665.4 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1104.6" cy="644" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_19" gradientUnits="userSpaceOnUse" x1="2036.6666" y1="-648.5428" x2="2079.9697" y2="-648.5428" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-19" d="M1083.5,644c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1082.6,639.6,1082.2,643.3,1083.5,644z"/>

          <linearGradient id="SVGID_2_19" gradientUnits="userSpaceOnUse" x1="2057.9573" y1="-659.3671" x2="2058.2878" y2="-615.7604" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-19" d="M1083.5,644c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1082.6,639.6,1082.2,643.3,1083.5,644z"/>

          <linearGradient id="SVGID_3_19" gradientUnits="userSpaceOnUse" x1="2036.6666" y1="-615.699" x2="2080.0046" y2="-615.699" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-19" d="M1083.5,644c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1082.6,639.6,1082.2,643.3,1083.5,644z"/>

          <radialGradient id="SVGID_4_19" cx="2058.2073" cy="-667.9293" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-19" cx="1104" cy="585.3" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1111.8,563.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1127,556.8,1120.2,563.6,1111.8,563.6z"/>
          <path className="st7" d="M1111.8,561.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1127,554.8,1120.2,561.6,1111.8,561.6z"/>
          <path className="st7" d="M1110.5,562.4l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1112.1,558.1,1112.1,560.8,1110.5,562.4z"/>
          <path className="st6" d="M1105.3,553.5l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1104.6,554,1105,553.8,1105.3,553.5z"/>
        </g>
      </g>
    </>
  );
};

export default HungarySVG;
