import React from 'react';

const SloveniaSVG = () => {

  const css = `.st2-18{fill:url(#SVGID_1_18);}
.st3-18{fill:url(#SVGID_2_18);}
.st4-18{fill:url(#SVGID_3_18);}
.st5-18{fill:url(#SVGID_4_18);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <polygon className="st0" points="904.7,634.3 915.7,616.4 922.8,607.2 926.1,608 939.7,612.3 953.2,616.8 961.6,620.2 966.1,622.4
							970.7,623.3 975.1,623.4 982.4,622.3 993.5,621.7 999.9,622.9 1008.3,623.7 1014.4,623.9 1026.6,622.4 1027.3,624.8 1028.3,626.3
							1029.4,630.1 1029.7,633 1024.6,633.9 1016.1,635.9 1010.8,636.9 1002.8,637.6 1000.7,638.6 995,644.6 993.1,646.3 989.4,646
							986.7,645.8 983.1,646.6 975.9,651.1 972.1,653.8 969.8,653.7 965.3,651.4 957.9,647.7 953.9,646.9 945.6,646.8 938.7,646.7
							932.2,645.8 930.2,645.9 930.7,644 930.7,641.4 929.7,639.2 927.1,636.9 924.3,635.8 920.9,635.2 914.1,634.7 908.4,634.8 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="940.8" cy="627" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_18" gradientUnits="userSpaceOnUse" x1="2200.4683" y1="-666.1851" x2="2243.7712" y2="-666.1851" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-18" d="M919.7,627c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C918.8,622.6,918.4,626.3,919.7,627z"/>

          <linearGradient id="SVGID_2_18" gradientUnits="userSpaceOnUse" x1="2221.759" y1="-677.0092" x2="2222.0896" y2="-633.4025" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-18" d="M919.7,627c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C918.8,622.6,918.4,626.3,919.7,627z"/>

          <linearGradient id="SVGID_3_18" gradientUnits="userSpaceOnUse" x1="2200.4683" y1="-633.3412" x2="2243.8062" y2="-633.3412" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-18" d="M919.7,627c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C918.8,622.6,918.4,626.3,919.7,627z"/>

          <radialGradient id="SVGID_4_18" cx="2222.009" cy="-685.5715" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-18" cx="940.2" cy="568.3" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M948,546.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2H948c8.4,0,15.2,6.8,15.2,15.2
								v16.3C963.2,539.8,956.4,546.6,948,546.6z"/>
          <path className="st7" d="M948,544.6h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2H948c8.4,0,15.2,6.8,15.2,15.2
								v16.3C963.2,537.8,956.4,544.6,948,544.6z"/>
          <path className="st7" d="M946.7,545.4l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C948.3,541.1,948.3,543.8,946.7,545.4z"/>
          <path className="st6" d="M941.5,536.5l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C940.8,537,941.2,536.8,941.5,536.5z"/>
        </g>
      </g>
    </>
  );
};

export default SloveniaSVG;
