import React, { useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl'

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import CountryMap from './CountryMap';
import FrontPage from './FrontPage';
import ContactPage from './ContactPage';
import RulesPage from './RulesPage';
import MiniRulesPage from './MiniRulesPage';
import FeedbackPage from './FeedbackPage';
import FailedPage from './FailedPage';

// helpers
import gameStates from '../helpers/gameState';
import { trackPageView } from '../helpers/gaHelper';

// custom hooks
import useGame from '../hooks/useGame';
import { Context } from './Wrapper';

// images
import LogoMyEU from '../assets/img/logo_my_eu.png';
import IconRules from '../assets/img/icons/icon_rules.svg';
import IconClose from '../assets/img/icons/icon_close.svg';

// scss
import '../scss/style.scss';
import Question from './Question';

const App = () => {

  const { gameState, showRules, showRulesPage, showFeedback, accessText, showMiniRules, stopGame, hideAccessText } = useGame();

  const location = useLocation();
  const context = useContext(Context);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  // render output
  return (
    <>
        <Header />
        <div className="lang-container">
          <button onClick={() => context.selectLanguage('et')}>EST</button>|  
          <button onClick={() => context.selectLanguage('ru')}>RUS</button>
        </div>
        <main>
          <CountryMap />
          {gameState === gameStates.NOT_STARTED && <FrontPage />}
          {gameState === gameStates.STARTED && <Question />}
          {gameState === gameStates.FINISHED && <ContactPage />}
          {gameState === gameStates.FAILED && <FailedPage />}
          {showRules && <RulesPage />}
          {showMiniRules && <MiniRulesPage />}
          {showFeedback && <FeedbackPage />}

          <div id="rules-btn" className="rules-btn">
            <img src={IconRules} className="rules-icon" onClick={() => showRulesPage()} alt="" />
            <span onClick={() => showRulesPage()}><FormattedMessage id="showGameRules" defaultMessage="Mängu reeglid"/></span>
          </div>

          <img src={LogoMyEU} className={classNames({
            my_eu: true,
            reveal: gameState !== gameStates.NOT_STARTED,
          })} alt="My EU" />

          {accessText && (
            <div className="end-message">
              <h1>{accessText}</h1>
              <div className="close">
                <span onClick={() => hideAccessText()}><FormattedMessage id="close" defaultMessage="Sulge"/></span>
                <img src={IconClose} className="close-icon" onClick={() => hideAccessText()} alt="Close" />
              </div>
            </div>
          )}

        </main>
    </>
  );
};

export default App;
