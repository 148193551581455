import React from 'react';

const LithuaniaSVG = () => {

  const css = `.st2-5{fill:url(#SVGID_1_5);}
               .st3-5{fill:url(#SVGID_2_5);}
               .st4-5{fill:url(#SVGID_3_5);}
               .st5-5{fill:url(#SVGID_4_5);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>


      <g className="country">
        <path className="st0" d="M1303,412.4l0.8,2.8l0.3,1.9l-0.4,2.6l-1.3,2.2l-2.5,2.8l-2.1,1.9l-3.8,2.1l-5,1.8l-1.6,1.3l-0.5,1.4l0.2,1.9
							l1.3,0.5l2.5,1l6,0.3l5,0.7l2.3,1l3.3,1.8l4.8,3.9l4.8,2.8l3,1.1l2.5-0.1l2.9-0.4l2.2,0.1l2.3,1l4.3,3.1l3.7,2.7l2.3,1.9l0.1,1.2
							l-0.4,2.1l-2.3,2.3l-2.6,3.5l-1.5,2.4l-0.4,1.9l-0.4,1.3l4.6,1.6l7.1,2.7l6.4,3.4l3.1,2.9l1.6,3.2l5.5,0.7l3.9,0.7l5.7,1.8l4.1,1.2
							l3.7,1.9l4.4,1.2l4.7-0.1l4.7-1l3.6-2.1c0,0,4.4-1.3,4.7-1.3c0.2,0,6.8-0.2,6.8-0.2l5.5,0.9l3.6,1.3l3.2,0.4l2.6-1.3l2.3-2.9
							l3.7-3.6l3.9-3.1l3.7-1.3l5.2-1.4l5.3-0.3l4.2-0.1l2.3-1l2.4-1.6l3-0.8l3.4,0.6l2.8-0.5l1.6-2.1l1-3.9l0.5-1.6l2-2.1l1.1-1.5
							l-21.4-15.9l-6.7-4.9l-3.1-1.2l-4.8-0.3l-2.6-0.7l-3.3-1.2l-5.1-6.1l-2.6-1.7l-3.7-0.4L1404,429l-6.1-0.8l-13.6-4.2l-12.7-2.8
							l-28.3-8.8l-5.9-0.8l-9.6,0.1l-3.6,0.6l-4,0.3l-4.6-0.6l-11.2,0.7L1303,412.4z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1426" cy="465.3" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_5" gradientUnits="userSpaceOnUse" x1="1714.5913" y1="-825.859" x2="1757.8943"
                          y2="-825.859" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-5" d="M1404.9,465.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1404,460.9,1403.6,464.6,1404.9,465.3z" />

          <linearGradient id="SVGID_2_5" gradientUnits="userSpaceOnUse" x1="1735.882" y1="-836.6832" x2="1736.2125"
                          y2="-793.0765" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-5" d="M1404.9,465.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1404,460.9,1403.6,464.6,1404.9,465.3z" />

          <linearGradient id="SVGID_3_5" gradientUnits="userSpaceOnUse" x1="1714.5913" y1="-793.0152" x2="1757.9292"
                          y2="-793.0152" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-5" d="M1404.9,465.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1404,460.9,1403.6,464.6,1404.9,465.3z" />

          <radialGradient id="SVGID_4_5" cx="1736.132" cy="-845.2455" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-5" cx="1425.4" cy="406.6" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M1433.2,384.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1448.4,378.1,1441.6,384.9,1433.2,384.9z" />
          <path className="st7" d="M1433.2,382.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1448.4,376.1,1441.6,382.9,1433.2,382.9z" />
          <path className="st7" d="M1431.9,383.7l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1433.5,379.4,1433.5,382.1,1431.9,383.7z" />
          <path className="st6" d="M1426.7,374.8l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1426,375.3,1426.4,375.1,1426.7,374.8z" />
        </g>
      </g>
    </>
  );
};

export default LithuaniaSVG;
