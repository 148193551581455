import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Estonian from '../lang/et.json';
import English from '../lang/en.json';
import Russian from '../lang/ru.json';
export const Context = React.createContext();
const local = 'et';
let lang;
lang = Estonian;

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);
    function selectLanguage(newLocale) {
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else if(newLocale === 'ru') {
            setMessages(Russian);
        } else {
            setMessages(Estonian);
        }
    }
    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale} defaultLocale="et">
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}
export default Wrapper;