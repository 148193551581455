import React from 'react';

const DenmarkSVG = () => {

  const css = `.st2-3{fill:url(#SVGID_1_3);}
               .st3-3{fill:url(#SVGID_2_3);}
               .st4-3{fill:url(#SVGID_3_3);}
               .st5-3{fill:url(#SVGID_4_3);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <polygon className="st0" points="918.8,373.5 937.2,378.1 953.3,382.6 953.8,381.4 950.5,376.6 947.6,372.5 948.1,370.6 950.4,369.4
							954.4,370.1 960,373.4 965.9,378.6 973,380.6 979.3,381.1 983,380 985.6,376 986.5,371.3 985.5,368.3 983.3,367.1 977.5,366.9
							972,366.7 969.8,365.8 967.7,364.2 967,362 967.9,360.6 971.3,359.1 982.5,353.7 985.6,353.1 996.4,352.8 1001.8,352.8
							1004.1,351.7 1004.7,349.8 1004,347.9 1001.2,344.8 995.9,341.7 993.9,339.9 993,337.4 993.9,334.1 997.8,330.7 1000.4,329.8
							1003.1,329.2 1006.5,327.4 1009.2,325.1 1012.1,321.5 1013.8,318.7 1013.5,316.3 1011.4,314.6 1005.8,313.7 1000.4,313.5
							994.5,314.6 991.3,315.9 988.4,318.1 985.9,320.3 983,321.8 979,321.8 972.7,320.6 969.2,319.9 967.1,320.3 966.5,321.5
							966.7,324.3 966.4,326.9 964.7,328.3 962.2,328.3 959.6,327 956,323.3 952.9,320.2 949,318.9 943.7,319.1 939.7,321.5 935.1,324.6
							931,327.6 927,329.8 923.7,332.8 920.4,337.2 917.6,343.1 916.3,348.3 916.3,351.9 917.3,354.6 919.3,358.6 919.9,361.5
							918.4,364.2 916,366.1 915.6,368.3 916.1,371 " />
        <polygon className="st0" points="1010.7,366.4 1014.8,365.4 1019.3,365.5 1024.9,366.9 1029,368.2 1031.3,367.3 1032.6,365.1
							1034.2,364.6 1035.3,365.5 1036.1,368.3 1037.5,371.1 1039.4,372.2 1043.4,371.4 1048.7,370.1 1051.6,369.7 1052.9,370.1
							1052.6,371.6 1050.8,373.4 1035.6,385.8 1034.5,387.6 1033.2,389.5 1029.6,391.4 1021.8,395.1 1017.2,396.9 1012.4,398.3
							1007.3,398.3 1003,397.1 998.7,394.6 996.3,391.1 996,387.9 997.9,386.7 1000.6,387.4 1004,388.8 1008.1,389.5 1012,388.9
							1013.5,387.4 1012.9,385.4 1009,382.3 1004.9,378 1003.7,374.3 1004.6,370.9 1006.8,368.5 " />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="975.6" cy="346.7" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_3" gradientUnits="userSpaceOnUse" x1="2164.5044" y1="-946.3708" x2="2207.8074"
                          y2="-946.3708" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-3" d="M954.5,346.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C953.6,342.3,953.2,346,954.5,346.7z" />

          <linearGradient id="SVGID_2_3" gradientUnits="userSpaceOnUse" x1="2185.7952" y1="-957.1951" x2="2186.1257"
                          y2="-913.5884" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-3" d="M954.5,346.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C953.6,342.3,953.2,346,954.5,346.7z" />

          <linearGradient id="SVGID_3_3" gradientUnits="userSpaceOnUse" x1="2164.5044" y1="-913.527" x2="2207.8423"
                          y2="-913.527" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-3" d="M954.5,346.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C953.6,342.3,953.2,346,954.5,346.7z" />

          <radialGradient id="SVGID_4_3" cx="2186.0452" cy="-965.7573" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-3" cx="975" cy="288" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M982.8,266.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C998,259.5,991.2,266.3,982.8,266.3z" />
          <path className="st7" d="M982.8,264.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C998,257.5,991.2,264.3,982.8,264.3z" />
          <path className="st7" d="M981.5,265.1l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C983.1,260.8,983.1,263.5,981.5,265.1z" />
          <path className="st6" d="M976.3,256.2l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C975.6,256.7,976,256.5,976.3,256.2z" />
        </g>
      </g>
    </>
  );
};

export default DenmarkSVG;
