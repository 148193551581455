const order = [{
  ID: 17, // estonia
}, {
  ID: 58, // finland
}, {
  ID: 48, // sweden
}, {
  ID: 60, // denmark
}, {
  ID: 38, // latvia
}, {
  ID: 34, // lithuania
}, {
  ID: 42, // poland
}, {
  ID: 52, // germany
}, {
  ID: 36, // luxembourg
}, {
  ID: 22, // netherlands
}, {
  ID: 26, // ireland
}, {
  ID: 13, // belgium
}, {
  ID: 62, // czech
}, {
  ID: 30, // greece
}, {
  ID: 32, // cyprus
}, {
  ID: 15, // bulgaria
}, {
  ID: 50, // romania
}, {
  ID: 24, // croatia
}, {
  ID: 56, // slovenia
}, {
  ID: 64, // hungary
}, {
  ID: 54, // slovakia
}, {
  ID: 11, // austria
}, {
  ID: 44, // portugal
}, {
  ID: 19, // spain
}, {
  ID: 46, // france
}, {
  ID: 28, // italy
}, {
  ID: 40, // malta
}
];

/*
 const order = [{
   ID: 17, // estonia
 }
 ];*/


export default order;
