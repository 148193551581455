import React from 'react';

const MaltaSVG = () => {

  const css = `.st2-26{fill:url(#SVGID_1_26);}
.st3-26{fill:url(#SVGID_2_26);}
.st4-26{fill:url(#SVGID_3_26);}
.st5-26{fill:url(#SVGID_4_26);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M752.9,925.7l-2,0.9l-1.2,1.1c0,0-0.5,2.3-0.5,2.4c0,0.1,0,1.4,0,1.4l-0.6,2.7l0.8,1.8l2.2,2
							c0,0,3.4,1.7,3.4,1.8c0.1,0.1,3.8,0.8,3.9,0.9c0.1,0,4.3,0,4.3,0l3.5-0.3l3.9-0.9l1.5,1.1l1,1.3l1.4,1.3l1.9-0.2l0.6-1.3l-0.6-1.6
							l-1.9-1V937c0,0,0.6-1.4,0.3-1.8c-0.2-0.3-1.3-2.2-1.3-2.2l-3.7-2.4l-4.4-2.3l-3.5-1.6l-2.8-1.3l-2.7-0.4l-2,0.4L752.9,925.7z"/>
        <path className="st0" d="M774.2,947.3l1-0.8l2-0.2l1.5-0.2l1.2-1.1l1.4-0.6l1.6,0.3l1.5,2.2l0.6,2.4c0,0,1.9,1.6,2,1.6s2.7,0.7,2.7,0.7
							h2c0,0,2.5,1.7,2.6,1.8s3.4,2.3,3.4,2.3l3.3,1l1.7,1.2l1.6,1.5l1.6,2.4l1.6,2l2.9,1.1l3.6,2.5c0,0,1.9,1.9,1.9,2s1.5,2.1,1.5,2.1
							l0.6,2.6v2.4l-0.7,3.4l-0.4,2.3l-0.8,1c0,0-1.7,1-1.8,1.1c0,0.1-2.6,1.2-2.6,1.2l-1.9,0.5c0,0-2.5,0.1-2.6,0.1s-3.8-0.2-3.8-0.2
							l-3.6-1.3l-1.9-0.5l-2.8-0.5l-2.8-0.5l-2.7-1.6l-3.7-2.3l-3.4-2.7l-3.2-2.5l-2.4-3l-1.8-2.3l-0.9-3v-1.9l0.9-1.6c0,0-0.1-2-0.1-2.1
							s-0.4-2-0.4-2l-1.1-2.3c0,0-0.7-2-0.7-2.1c0-0.2-0.4-2.1-0.4-2.5s0.6-2,0.6-2l0.8-1.1L774.2,947.3z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="801.3" cy="967.3" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_26" gradientUnits="userSpaceOnUse" x1="2341.3062" y1="-326.4293" x2="2384.6091" y2="-326.4293" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-26" d="M780.2,967.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C779.3,962.9,778.9,966.6,780.2,967.3z"/>

          <linearGradient id="SVGID_2_26" gradientUnits="userSpaceOnUse" x1="2362.5969" y1="-337.2535" x2="2362.9275" y2="-293.6468" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-26" d="M780.2,967.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C779.3,962.9,778.9,966.6,780.2,967.3z"/>

          <linearGradient id="SVGID_3_26" gradientUnits="userSpaceOnUse" x1="2341.3062" y1="-293.5855" x2="2384.644" y2="-293.5855" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-26" d="M780.2,967.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C779.3,962.9,778.9,966.6,780.2,967.3z"/>

          <radialGradient id="SVGID_4_26" cx="2362.8469" cy="-345.8158" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-26" cx="800.7" cy="908.6" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M808.5,886.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C823.7,880.1,816.9,886.9,808.5,886.9z"/>
          <path className="st7" d="M808.5,884.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C823.7,878.1,816.9,884.9,808.5,884.9z"/>
          <path className="st7" d="M807.2,885.7l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C808.8,881.4,808.8,884.1,807.2,885.7z"/>
          <path className="st6" d="M802,876.8l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C801.3,877.3,801.7,877.1,802,876.8z"/>
        </g>
      </g>
    </>
  );
};

export default MaltaSVG;
