import React from 'react';

const LatviaSVG = () => {

  const css = `.st2-4{fill:url(#SVGID_1_4);}
               .st3-4{fill:url(#SVGID_2_4);}
               .st4-4{fill:url(#SVGID_3_4);}
               .st5-4{fill:url(#SVGID_4_4);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M1428.4,385.5l-3.2,0.5l-2.8,1.2l-3.2,1.8l-0.9,2l-0.8,4.3l-1.4,5.9l-1.1,2.7l-2.8,1.9l-2.6,1.3l-5.4,0.6
							l-4.3-0.1l-6.6-0.8l-5.7-1.3l-3.3-1.4l-3.8-3.1l-4.2-5.1l-5.7-7.7l-3.7-6.1l-1.7-2.6l-3.1-1l-3.4,0.2l-3.9,0.7l-2.9,1.8l-4,0.6
							l-4.7,0.2l-4.1,1l-5.7,0.3l-3.4,0.9l-3.1,2l-3.2,2.8l-3,2.4l-2.1,2.2l-3.6,1.7l-4.6,0.8l-4.7,2.4l-2.3,2.2l-1.6,3.1l-0.7,3.3l1,3.6
							l0.8,2l1.4,0.1l5.3-0.3l5.9-0.3l4.6,0.6l4-0.3l3.6-0.6l9.6-0.1l5.9,0.8l12.9,3.8l15.4,5l6.3,1.3l6.3,1.4l7.2,2.3l6.3,1.9l6.1,0.8
							l6.8,0.1l6.8,0.1l3.7,0.4l2.6,1.7l2.8,3.3l2.3,2.8l3.3,1.2l2.6,0.7l4.8,0.3l3.1,1.2l6.7,4.9l11,8.4l10.4,7.4l4.1-1.9l4.1-1.6
							l4.9-0.2l4-0.2l4.4,0.4l4.8,1.7l5.8,1.7l3.6,0.3l3.4-1.8l4.7-2.3l2.8-0.7l3.9,0.3l6.1,0.2c0,0-0.4-6.1-0.4-6.4s-0.2-4.8-0.2-4.8
							l-2.2-2.3l-4.1-4.6l-2-4.1l0.1-1.9l1-2.2l2.6-3.1l0.7-2.8l-1.9-3.1l-3.9-4.6l-2.4-2.2l-7.1-1.3l-3.7-1.2l-8.4-1.6l-5.9-1.5l-6.4-0.9
							l-2.5-1.3l-12.9-10l-7.1-4.6l-4.4-1.8l-5.6-1.2l-7.7-1.3l-8.1-0.3l-4-0.9L1428.4,385.5z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1482.7" cy="442.5" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_4" gradientUnits="userSpaceOnUse" x1="1657.795" y1="-848.4409" x2="1701.098"
                          y2="-848.4409" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-4" d="M1461.6,442.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1460.7,438.1,1460.3,441.8,1461.6,442.5z" />

          <linearGradient id="SVGID_2_4" gradientUnits="userSpaceOnUse" x1="1679.0856" y1="-859.2651" x2="1679.4163"
                          y2="-815.6584" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-4" d="M1461.6,442.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1460.7,438.1,1460.3,441.8,1461.6,442.5z" />

          <linearGradient id="SVGID_3_4" gradientUnits="userSpaceOnUse" x1="1657.795" y1="-815.597" x2="1701.1328"
                          y2="-815.597" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-4" d="M1461.6,442.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1460.7,438.1,1460.3,441.8,1461.6,442.5z" />

          <radialGradient id="SVGID_4_4" cx="1679.3357" cy="-867.8273" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-4" cx="1482.1" cy="383.8" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M1489.9,362.1h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1505.1,355.3,1498.3,362.1,1489.9,362.1z" />
          <path className="st7" d="M1489.9,360.1h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1505.1,353.3,1498.3,360.1,1489.9,360.1z" />
          <path className="st7" d="M1488.6,360.9l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1490.2,356.6,1490.2,359.3,1488.6,360.9z" />
          <path className="st6" d="M1483.4,352l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6V323c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3c0.3,0.3,0.7,0.5,1.2,0.5
								C1482.7,352.5,1483.1,352.3,1483.4,352z" />
        </g>
      </g>
    </>
  );
};

export default LatviaSVG;
