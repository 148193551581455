import React from 'react';

const CzechSVG = () => {

  const css = `.st2-12{fill:url(#SVGID_1_12);}
.st3-12{fill:url(#SVGID_2_12);}
.st4-12{fill:url(#SVGID_3_12);}
.st5-12{fill:url(#SVGID_4_12);}}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <polygon className="st0" points="986.2,562.1 990.5,563.7 995.7,566 1001.6,566.9 1011.1,566.7 1014.1,565.5 1017.9,562.7 1021.6,559.7
							1023.6,558.6 1026.1,558.6 1040.7,564 1046.2,566.6 1049.9,570.6 1054.1,574.3 1058.1,576.8 1065.3,577.2 1069,576.9 1072.9,577.2
							1074.2,578.4 1073.8,581.1 1073.9,582.7 1077,580.8 1080.6,578.4 1084,577.7 1089.6,578.3 1100.3,578.9 1110.2,578.3 1115.7,577.2
							1123.3,574.4 1131,571.4 1139.6,570.4 1145.5,569.7 1145.9,568.3 1143.9,563.1 1142.1,558.7 1140.1,556.9 1135.1,555.4
							1126.2,553.1 1122.5,551.3 1121.2,549.2 1121.2,544.5 1119.7,542.4 1113.3,539.9 1104.4,536.6 1100.9,536.8 1096.9,538.7
							1093.9,540.3 1091.6,540.3 1088.4,538.4 1083.9,534 1082.8,531.1 1082.2,527.9 1079.7,525.1 1073,521.1 1064,516.2 1056.6,510.9
							1051.7,507.1 1049.2,508.8 1046.5,510 1043.7,509.4 1040.6,508.2 1035.1,505.8 1032.1,505.8 1027.7,506 1014.2,506.3 1004.6,506.4
							1001.5,507.4 997.6,508.3 988.4,508.6 970.5,507.9 961,507.9 958.4,509.5 958.2,512.6 959.6,516.2 960.4,519.9 959.7,524.2
							958.5,530.6 959.1,534.6 961.8,538.1 978.5,552 984.6,558.6 985.6,560.3 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1064.8" cy="551.9" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_12" gradientUnits="userSpaceOnUse" x1="2076.1697" y1="-740.8012" x2="2119.4727" y2="-740.8012" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-12" d="M1043.7,551.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1042.8,547.5,1042.4,551.2,1043.7,551.9z"/>

          <linearGradient id="SVGID_2_12" gradientUnits="userSpaceOnUse" x1="2097.4604" y1="-751.6254" x2="2097.791" y2="-708.0187" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-12" d="M1043.7,551.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1042.8,547.5,1042.4,551.2,1043.7,551.9z"/>

          <linearGradient id="SVGID_3_12" gradientUnits="userSpaceOnUse" x1="2076.1697" y1="-707.9574" x2="2119.5076" y2="-707.9574" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-12" d="M1043.7,551.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1042.8,547.5,1042.4,551.2,1043.7,551.9z"/>

          <radialGradient id="SVGID_4_12" cx="2097.7104" cy="-760.1876" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-12" cx="1064.2" cy="493.2" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1072,471.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V440c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1087.2,464.7,1080.4,471.5,1072,471.5z"/>
          <path className="st7" d="M1072,469.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V438c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1087.2,462.7,1080.4,469.5,1072,469.5z"/>
          <path className="st7" d="M1070.7,470.3l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1072.3,466,1072.3,468.7,1070.7,470.3z"/>
          <path className="st6" d="M1065.5,461.4l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1064.8,461.9,1065.2,461.7,1065.5,461.4z"/>
        </g>
      </g>

    </>
  );
};

export default CzechSVG;
