import React from 'react';

const LuxembourgSVG = () => {

  const css = `.st2-8{fill:url(#SVGID_1_8);}
               .st3-8{fill:url(#SVGID_2_8);}
               .st4-8{fill:url(#SVGID_3_8);}
               .st5-8{fill:url(#SVGID_4_8);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M742.7,485.9l2.6,0.8l4.1,1.3l7.3,2.3l5.9,1.2l1.1-2.4l1.2-3.3l0.7-2.9l0.1-2.7l-1.1-3.1c0,0-1.1-2.7-1.1-2.9
					c0-0.2,0.2-1.9,0.2-1.9l-0.1-0.7l-6.7,1.6l-4.1,2.3l-4.9,4l-3.4,4.3L742.7,485.9z" />
      </g>

      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="756.2" cy="473.7" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_8" gradientUnits="userSpaceOnUse" x1="2384.3921" y1="-820.2726" x2="2427.6951"
                          y2="-820.2726" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{ stopColor: '#FFCD00' }} />
            <stop offset="0.25" style={{ stopColor: '#FFE100' }} />
            <stop offset="0.2883" style={{ stopColor: '#FFE204' }} />
            <stop offset="0.3286" style={{ stopColor: '#FFE311' }} />
            <stop offset="0.3697" style={{ stopColor: '#FFE626' }} />
            <stop offset="0.4115" style={{ stopColor: '#FFE943' }} />
            <stop offset="0.4537" style={{ stopColor: '#FFEE69' }} />
            <stop offset="0.4963" style={{ stopColor: '#FFF398' }} />
            <stop offset="0.5384" style={{ stopColor: '#FFFACD' }} />
            <stop offset="0.5647" style={{ stopColor: '#FFFEF3' }} />
            <stop offset="0.692" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.8818" style={{ stopColor: '#DDB918' }} />
            <stop offset="1" style={{ stopColor: '#CB9925' }} />
          </linearGradient>
          <path className="st2-8" d="M735.1,473.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C734.2,469.3,733.8,473,735.1,473.7z" />

          <linearGradient id="SVGID_2_8" gradientUnits="userSpaceOnUse" x1="2405.6826" y1="-831.0969" x2="2406.0132"
                          y2="-787.4902" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{ stopColor: '#D97428' }} />
            <stop offset="0.2913" style={{ stopColor: '#E99517' }} />
            <stop offset="0.7079" style={{ stopColor: '#FFC000' }} />
            <stop offset="0.7739" style={{ stopColor: '#FFC600' }} />
            <stop offset="0.8613" style={{ stopColor: '#FFD700' }} />
            <stop offset="0.9602" style={{ stopColor: '#FFF200' }} />
            <stop offset="1" style={{ stopColor: '#FFFF00' }} />
          </linearGradient>
          <path className="st3-8" d="M735.1,473.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C734.2,469.3,733.8,473,735.1,473.7z" />

          <linearGradient id="SVGID_3_8" gradientUnits="userSpaceOnUse" x1="2384.3921" y1="-787.4288" x2="2427.7297"
                          y2="-787.4288" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="0" style={{ stopColor: '#FFC600' }} />
            <stop offset="6.884691e-02" style={{ stopColor: '#FFC704' }} />
            <stop offset="0.1411" style={{ stopColor: '#FFCA11' }} />
            <stop offset="0.2149" style={{ stopColor: '#FFCF26' }} />
            <stop offset="0.2898" style={{ stopColor: '#FFD643' }} />
            <stop offset="0.3656" style={{ stopColor: '#FFDE69' }} />
            <stop offset="0.442" style={{ stopColor: '#FFE998' }} />
            <stop offset="0.5176" style={{ stopColor: '#FFF5CD' }} />
            <stop offset="0.5647" style={{ stopColor: '#FFFEF3' }} />
            <stop offset="0.7232" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.7269" style={{ stopColor: '#FEF201' }} />
            <stop offset="0.9143" style={{ stopColor: '#D7A120' }} />
            <stop offset="1" style={{ stopColor: '#C8812C' }} />
          </linearGradient>
          <path className="st4-8" d="M735.1,473.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C734.2,469.3,733.8,473,735.1,473.7z" />

          <radialGradient id="SVGID_4_8" cx="2405.9326" cy="-839.6591" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="2.643507e-02" style={{ stopColor: '#FFFEEE' }} />
            <stop offset="7.899498e-02" style={{ stopColor: '#FFFCC1' }} />
            <stop offset="0.1528" style={{ stopColor: '#FFF978' }} />
            <stop offset="0.2441" style={{ stopColor: '#FFF516' }} />
            <stop offset="0.2634" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.5731" style={{ stopColor: '#DFA51F' }} />
            <stop offset="0.7187" style={{ stopColor: '#D2852B' }} />
            <stop offset="0.7874" style={{ stopColor: '#D4882C' }} />
            <stop offset="0.8453" style={{ stopColor: '#D8932E' }} />
            <stop offset="0.8992" style={{ stopColor: '#E1A431' }} />
            <stop offset="0.9504" style={{ stopColor: '#ECBD35' }} />
            <stop offset="1" style={{ stopColor: '#FBDC3B' }} />
          </radialGradient>
          <circle className="st5-8" cx="755.6" cy="415" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M763.4,393.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C778.6,386.5,771.8,393.3,763.4,393.3z" />
          <path className="st7" d="M763.4,391.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C778.6,384.5,771.8,391.3,763.4,391.3z" />
          <path className="st7" d="M762.1,392.1l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C763.7,387.8,763.7,390.5,762.1,392.1z" />
          <path className="st6" d="M756.9,383.2l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7H755c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3c0.3,0.3,0.7,0.5,1.2,0.5
								C756.2,383.7,756.6,383.5,756.9,383.2z" />
        </g>
      </g>

    </>
  );
};

export default LuxembourgSVG;
