import React from 'react';

const SpainSVG = () => {

  const css = `.st2-23{fill:url(#SVGID_1_23);}
.st3-23{fill:url(#SVGID_2_23);}
.st4-23{fill:url(#SVGID_3_23);}
.st5-23{fill:url(#SVGID_4_23);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M153.5,572.2l5.8-6.5l4.9-5.3l1.3-3.1l-0.3-4.1l-1-2.4l-1.6-2.8l-0.3-2.8l0.6-1.8l2.7-2.7l3.7-2.2l3.6-0.6
							l6.2,0.9l5.2,1.3l4.3,0.3l6.1-0.7l9-2.4l7.3-1.5l4.6-0.4l5.2,0.7c0,0,2.2,0.6,2.5,1.2s2.8,3,2.8,3s5.2,4,5.6,4.3
							c0.4,0.3,5.6,2.2,5.6,2.2l14.5,3.3l20,4.6l25.6,6.1l29.2,6.8l21.9,5l7.9,1.6l7,3l19.4,6.7l16.1,5.4l19.1,13.9l18.8,13.6l4.7,2.9
							l4,1.6l4.3-0.1l3.7-0.2l3.3-1l2.3-1.6l2.3-0.9l1.4-0.7l3.3,0.9l2.2,2.6l3.1,2.3l2,3.8l4.7,2.7l14.8,5.9l25.2,8.2l18.6,4.9l-1.6,3.2
							l-1.9,2.7l-2.8,2.5l-3.3,2.1l-3.6,1.6l-5.5,1.2l-5.8,0.6l-5.9,0.4l-3.1,0.3l-4.1,1.2l-6.2,1.3l-4.7,0.4l-5.9,0.7l-5.5,0.1l-5-0.3
							l-8.3-0.4l-9.9-0.6l-8.3-0.6h-5l-4.3,1.2l-3.4,1.8l-1.6,1.9l-2.1,1.8c0,0-2.2,1.6-2.8,1.9c-0.6,0.3-4.1,1.9-4.1,1.9l-5.9,0.6
							l-11.1,2.7l-12.4,4.1l-7.1,3.6l-7.3,4.7l-4.1,3.9l-3.1,3.7l-2.1,4.6l-2.8,8.3l-2.2,5l-1.5,1.6l-3.9,1.5l-7.6,1.2l-8,1.6l-7.6,2.4
							l-5.5,1.3l-3.3,1.6l-2.4,2.4l-4.3,5l-3.4,2.8l-4.9,3.6l-3.7,1l-5.6-0.4l-10.2-0.7l-9.8,0.4l-6.5,1.2l-5.6,2.7l-4.6,2.8l-4.1,1.9
							l-4.9,1l-3.7,0.4l-6.1-0.1l-4.6-0.4l-7-2.2l-16.1-3l-16.6-4.6l-9-2.2l-8.4,0l-6.5,0.4l-5.8,0.4l-6.7,1h-4.9l-5.3-1l-5.5-2.1
							l-3.6-0.7l-4.3-0.3l-3.9,0.6l-2.4,1.6l-2.5,2.1l-3.3,0.9l-3.1,0.4l-3.7-1.2l-3-1.8l-2.2-1.8c0,0-1.3-2.5-1.5-3
							c-0.1-0.4-0.6-5.3-0.6-5.3l-1.3-8.7c0,0-1.2-7.1-1.3-7.6c-0.1-0.4-2.4-4.6-2.4-4.6l-2.8-3l-5-1.5l-5.3-0.9l-4.1-0.4
							c0,0-0.3-3.1-0.3-3.6s1.3-5.2,1.3-5.2l3.7-3.9l4.4-3.4l4.3-1.9l3.7-1l5.6,0.4l5.9,0.3l2.5-1.6l1.5-1.9l-1-3.1l-3.7-5.5l-0.1-2.4
							l2.5-3.1l6.7-4l5-1.6h2.4l2.2,0.9l2.8-0.6l0.3-3.1l-1.3-5.8l-2.2-5l-0.7-3l1.6-3l2.5-1.6l4,0.6l5.9,1l4.3,0.7l1.9-1.2l3.1-4.7l3-4
							l4.3-3.1l7-2.1l3.1-3l3.7-6.2l1.3-5.5l3-5l3.1-1.9l5.8-0.9l12.3-1.6l8.7-1.9l3.1-1.5l0.4-2.2l-1.6-1.9l-4.1-2.5l0.3-1.8l1.3-1.6
							l-0.9-3l-2.1-1.2l-4.9-0.6l-2.8-1.3l-4.7,0.3l-4.4,0.6l-4.4,1.5l-1.9-2.1l-1.3-0.9l-5-1.2c0,0-5-1-5.8-1s-5.5-2.1-5.5-2.1l-1.8-1.6
							l-0.4-1.9l1.3-2.1l-1.3-2.5l-1.9-0.7c0,0-3.6-0.4-4.7,0.1c-1.2,0.6-4.6,2.2-4.6,2.2l-3.6,0.6L153.5,572.2z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="302.5" cy="654.3" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_23" gradientUnits="userSpaceOnUse" x1="2838.8289" y1="-641.5068" x2="2882.1318" y2="-641.5068" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-23" d="M281.4,654.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C280.5,649.9,280.1,653.6,281.4,654.3z"/>

          <linearGradient id="SVGID_2_23" gradientUnits="userSpaceOnUse" x1="2860.1194" y1="-652.331" x2="2860.4502" y2="-608.7243" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-23" d="M281.4,654.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C280.5,649.9,280.1,653.6,281.4,654.3z"/>

          <linearGradient id="SVGID_3_23" gradientUnits="userSpaceOnUse" x1="2838.8289" y1="-608.663" x2="2882.1667" y2="-608.663" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-23" d="M281.4,654.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C280.5,649.9,280.1,653.6,281.4,654.3z"/>

          <radialGradient id="SVGID_4_23" cx="2860.3696" cy="-660.8932" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-23" cx="301.9" cy="595.6" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M309.7,573.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C324.9,567.1,318.1,573.9,309.7,573.9z"/>
          <path className="st7" d="M309.7,571.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C324.9,565.1,318.1,571.9,309.7,571.9z"/>
          <path className="st7" d="M308.4,572.7l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C310,568.4,310,571.1,308.4,572.7z"/>
          <path className="st6" d="M303.2,563.8l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C302.5,564.3,302.9,564.1,303.2,563.8z"/>
        </g>
      </g>
    </>
  );
};

export default SpainSVG;
