import React from 'react';

const GermanySVG = () => {

  const css = `.st2-7{fill:url(#SVGID_1_7);}
               .st3-7{fill:url(#SVGID_2_7);}
               .st4-7{fill:url(#SVGID_3_7);}
               .st5-7{fill:url(#SVGID_4_7);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M850,398.6l3.3-2.7l2.4-1l4.9-0.5h5.7l4.7,0.7l3.5,1.2l5,3.1l6,2.8l6.2,2.6l3.2,1l1.9-0.5l2.1-1.6l2.1-1
							l1.8-0.1l4.1,0.6l4.2,0.6l2.7-0.2l1.5-1.1l0.7-2.8l-0.3-3.4l-1.6-3.9l-0.4-3l0.4-3.1l1.7-2.1l2.2-1.6l1.6-3l-0.5-3.3l-2-4l1.7,1.6
							l18.4,4.6l16.1,4.4l0.9,0.1l0.4,1.1l-0.2,1.4l-0.7,1.3l-0.8,1.2l-0.4,1.4l0.1,1.3l1,1.6l1.4,1.6l1.9,1.3l2.8,1.3l2.6,1l5.8,0.4h4.4
							h9.9l2.5,0.6l1,1l0.1,1l-1.1,1.2l-2.4,1l-4,1.3l-2.4,1.9l-0.6,1v1.6l1.2,2l2.6,1.5l2.7,1.3l3.7,0.5l4.4,0.1l8.9-1.6l7.3-1.9l6.6-1.6
							l6.7-1.2l7.4-0.4c0,0,3.8-0.1,3.9,0.1s2.8,1.5,2.8,1.5l3.9,0.9l3.8,0.1l5.3-0.3l10.3-0.7h4.3l1.2,1.1l0.1,0.9l-0.4,1l-1.3,1.1
							l-3.3,1.2l-3.3,1.2l-3.3,1.2l-0.6,0.7l-0.2,1.2l0.8,0.7l2.1,0.7l8.2,1.5l3.8,1.3l2.7,1.7l1.1,1.6l1,2.7l0.3,3.7c0,0-0.4,2.9-0.4,3.1
							s-0.5,2.7-0.5,2.7l-1.8,4l-1.6,3.5l-2.8,3.2l-2.7,2.4l-3.2,1.6l-2.1,1.6l-1.9,1.4l-0.4,1.1l0.4,1l1.6,1.6l2.1,2.1l1.9,2.1l0.4,1.9
							c0,0-0.3,1.9-0.4,2.4c-0.1,0.4-0.9,2.5-0.9,2.5l-1,1.7l0.3,1.1l0.7,2l0.3,2.7l-1.7,3.7l-1.6,3.5l-1.1,5.3l0.1,3.9
							c0,0,0.4,1.9,0.4,2.1s1.5,2,1.5,2l0.9,2l-1,1.8l-1.6,2.4l-2.1,2.8l-2.5,1.6l-2.7,1.2l-2.8-0.6l-8.6-3.6h-3l-27.6,0.6l-7,1.9
							l-9.2,0.3l-17.9-0.7H961l-2.7,1.6l-0.1,3.1l1.3,3.6l0.9,3.7l-1.9,10.7l0.6,4l2.7,3.6l16.7,13.9l6.1,6.5l1,1.8l0.6,1.8h-2.7h-8.9
							l-6.5,0.4l-4.7,1.8l-2.4,1.2l-12.7,1.6l-3,1.5l-2.1,4l-0.7,3.9l-1.3,3.4l-3.4,1.9l-3.4,0.1l-4.9-2.2l-5.8-3.3l-11.9-2.2l-8-1
							l-6.7,0.1l-6.4,0.7l-4.4,0.9l-5.3,0.3l-5.3-1.3l-8.6-5l-6.2-1.5l-4-0.4l-4,1.3l-2.4,1.3l-3,0.3l-3-1.7l-3.3-3.5l-2.9-2.4l-4-0.9
							l-2.9,0.7l-2.1,2.1l-0.4-1.9l-2-1.2l-2.8-1.9l-3.5-2.5l-4.7-2.4l-6-2.4l-4.7-1.6l-2.7-0.5l-3.3,0.4l-7.3,0.3l-6.5-0.1l-5.3-1
							l-4.7-1.6l-5.1-1.2l-2,0.1l-2.3,0.2l-2.4,0.7l-1.1-0.6l4.6-2.7l5.1-5.2l4.6-5.6l6.7-5.9l4.4-3.2l7.3-2.9l5.2-1.7l3.9-0.7l1.6-1.2
							l-0.3-2.3l-1.1-1.3l-2.2-0.9l-4.8-0.8l-2.9-1.6l-3.1-2.4l-4-1.4l-3.8-1.3l-4.9-1.2l-4.1-1.9l-3.2-2.7l-2.4-2l-1.4-2.8l-2.2-2.2
							l-2.8-1.4l2.3-5.7l0.7-2.9l0.1-2.7l-2.2-6l0.2-1.9l0.5-1l4.1-0.9l6.1-2.6l0.7-3.7l-2.4-5.7l0.4-2.6l1.8-2.1l13.5-13.9l2.2-2.2l1-2.7
							l1.3-2.1l1.6-0.1l1.9,0.4l14.2,0.7l2.4-0.7l3.7-3.9l1.9-1.3l3.3-1l2.2-0.2l1.7-1l0.8-1.9l-0.3-1.7l-2.1-3.3l-2.4-2.7l-0.4-1.6
							l0.6-1.2l1.9-0.5l7.9-0.7l7.5-1.3l3.4-1.3l2-1.1l2.1-2l1.3-2.3l1.2-3.3L850,398.6z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="877.6" cy="455.4" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_7" gradientUnits="userSpaceOnUse" x1="2262.9116" y1="-838.0337" x2="2306.2146"
                          y2="-838.0337" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-7" d="M856.5,455.4c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C855.6,451,855.2,454.7,856.5,455.4z" />

          <linearGradient id="SVGID_2_7" gradientUnits="userSpaceOnUse" x1="2284.2024" y1="-848.8579" x2="2284.533"
                          y2="-805.2512" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-7" d="M856.5,455.4c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C855.6,451,855.2,454.7,856.5,455.4z" />

          <linearGradient id="SVGID_3_7" gradientUnits="userSpaceOnUse" x1="2262.9116" y1="-805.1899" x2="2306.2495"
                          y2="-805.1899" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-7" d="M856.5,455.4c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C855.6,451,855.2,454.7,856.5,455.4z" />

          <radialGradient id="SVGID_4_7" cx="2284.4524" cy="-857.4202" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-7" cx="877" cy="396.7" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M884.8,375h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C900,368.2,893.2,375,884.8,375z" />
          <path className="st7" d="M884.8,373h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C900,366.2,893.2,373,884.8,373z" />
          <path className="st7" d="M883.5,373.8l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C885.1,369.5,885.1,372.2,883.5,373.8z" />
          <path className="st6" d="M878.3,364.9l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C877.6,365.4,878,365.2,878.3,364.9z" />
        </g>
      </g>

    </>
  );
};

export default GermanySVG;
