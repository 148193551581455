import React from 'react';
import classNames from 'classnames';
import countryStates from '../helpers/countryState';

const Country = ({ data }) => {
  // render output
  return (
    <g className={classNames({
      step: true,
      active: data.activeState === countryStates.ACTIVE,
      done: data.activeState === countryStates.DONE,
    })} >
      {data.render}
    </g>
  );
};

export default Country;
