import React from 'react';

const BelgiumSVG = () => {

  const css = `.st2-11{fill:url(#SVGID_1_11);}
.st3-11{fill:url(#SVGID_2_11);}
.st4-11{fill:url(#SVGID_3_11);}
.st5-11{fill:url(#SVGID_4_11);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M671.3,426.6l4.7,0.4l4.8-0.1l6.9-0.4l6.3-1.1l1.7,0.1l1.7,2l3,2.2l4.8,1l5.7,0.3h8.2l8.7-0.3l7-0.7l4.9-0.7
							l3.4,0.1l5.7,1l3.3,2.3l4.2,4.7l5.7,4.2l5.9,5.4l4.2,4.1l2.8,2.9l-1.8,2.1l-0.4,2.6l1.6,3.4l0.9,2.2l-0.7,3.7l-2.3,1.1l-3.8,1.4
							l-5.6,1.2l-5.9,1.4l-4.1,2.3l-4.9,4l-3.4,4.3l-1.7,1.9c0,0-1.9,0.8-2.2,0.8s-3.1-0.2-3.1-0.2l-2.2-1.6c0,0-2.7-4.2-2.9-4.6
							c-0.2-0.3-2.4-3.3-2.4-3.3l-2.8-1l-2.2-0.8l-2-1.3l-1-2.3l0.8-2.6l1.1-2.9l0.3-2.1l-0.9-1.1l-1.8,0.3l-3.4,0.9l-6.9,1.8l-5.1,0.6
							l-1.7-0.7l-2.1-2l-0.2-2.8l1.4-2.3l-0.8-2.1l-5-4.1l-6.1-3.7l-5.9-5.3l-3.9-4.7l-2.3-0.9l-2.8,0.4l-2-0.9l-2.4-2.6l-0.7-2.4l1.1-2.9
							l-1.1-1.3L671.3,426.6z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="728.1" cy="447.5" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_11" gradientUnits="userSpaceOnUse" x1="2412.395" y1="-846.6097" x2="2455.698" y2="-846.6097" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-11" d="M707,447.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C706.1,443.1,705.7,446.8,707,447.5z"/>

          <linearGradient id="SVGID_2_11" gradientUnits="userSpaceOnUse" x1="2433.6858" y1="-857.434" x2="2434.0164" y2="-813.8273" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-11" d="M707,447.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C706.1,443.1,705.7,446.8,707,447.5z"/>

          <linearGradient id="SVGID_3_11" gradientUnits="userSpaceOnUse" x1="2412.395" y1="-813.7659" x2="2455.7329" y2="-813.7659" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-11" d="M707,447.5c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C706.1,443.1,705.7,446.8,707,447.5z"/>

          <radialGradient id="SVGID_4_11" cx="2433.9358" cy="-865.9962" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-11" cx="727.5" cy="388.8" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M735.3,367.1H719c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C750.5,360.3,743.7,367.1,735.3,367.1z"/>
          <path className="st7" d="M735.3,365.1H719c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C750.5,358.3,743.7,365.1,735.3,365.1z"/>
          <path className="st7" d="M734,365.9l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C735.6,361.6,735.6,364.3,734,365.9z"/>
          <path className="st6" d="M728.8,357l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6V328c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3c0.3,0.3,0.7,0.5,1.2,0.5
								C728.1,357.5,728.5,357.3,728.8,357z"/>
        </g>
      </g>
    </>
  );
};

export default BelgiumSVG;
