import React from 'react';

const IrelandSVG = () => {

  const css = `.st2-10{fill:url(#SVGID_1_10);}
.st3-10{fill:url(#SVGID_2_10);}
.st4-10{fill:url(#SVGID_3_10);}
.st5-10{fill:url(#SVGID_4_10);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M388.4,283.4l4.7,1l5.3,0.2l9.3-0.1l12-0.2l5.9-0.8l2.7-1.3l0.9-1.6l-1.2-2.2l-4.4-2l-1.2-2.4l0.7-2.6l2.6-1.1
							l6.9-1.2l5.9,0.8l7.8,2.2l7,2.8l1.8,0.4l-3,2.2c0,0-3,1.8-3.3,1.9s-5,1.8-5,1.8l-5.9,0.9l-5.8,2.2l-4.6,2.1l-1.9,1.6l0.1,1.9
							l1.2,1.3l2.8,1.4l4.1,2.7l2.9,2.4l2,0.7l1.9-0.8l4.1-3.4l4.7-2.7l3.6-0.8l3,0.6l2.7,2.4l1.9,4.2l2.8,3.8l1.1,1.8l-1,2.7l-0.7,3.6
							l-1.8,4.6l-3.1,5.3l-3.4,5.3l-3.3,4.8l-4.3,5.3l-3.9,3.8l-3.7,3l-3.1,1.7l-3.3,0.9l-2.8,0.6h-3.7l-3-0.3l-5.2-0.9l-3.6-1.3l-3.9-1.6
							l-5-0.7l-5.9-0.3l-3.8,0.6l-4.7,1.4l-7.1,1l-5.6,0.9l-7.4,1l-6.8,0.6l-11.7,0.1l-12.7-0.1l-5.2-0.6l-5.1-1.7l-4.2-3l-4.6-4l-3.1-3.8
							l-1.3-4l1.1-2l1.8-1.3l3.1-0.8l6.1-0.8l7.2-0.6l4.7-0.7l3.3-1.3l3.3-2.2l4.7-2.9l5.8-1.9l6-1.9l3.6-2.1l0.9-2.8l-1.4-3l-4.6-4.2
							l-2.8-3l-0.2-2.3l2.1-1.6l4.3-2.2l3.7-2.7L369,285l0.4-2.1l1.9-1.8l2.7-0.8h4.6l3.8,0.9l3.3,1.3L388.4,283.4z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="402.1" cy="318.6" rx="22.4" ry="5.6"/>

        <linearGradient id="SVGID_1_10" gradientUnits="userSpaceOnUse" x1="2737.824" y1="-976.8333" x2="2781.127" y2="-976.8333" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
          <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
          <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
          <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
          <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
          <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
          <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
          <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
          <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
          <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
          <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
          <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
          <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
          <stop  offset="1" style={{stopColor: "#CB9925"}}/>
        </linearGradient>
        <path className="st2-10" d="M381,318.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C380.1,314.2,379.7,317.9,381,318.6z"/>

        <linearGradient id="SVGID_2_10" gradientUnits="userSpaceOnUse" x1="2759.1145" y1="-987.6575" x2="2759.4453" y2="-944.0508" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
          <stop  offset="0" style={{stopColor: "#D97428"}}/>
          <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
          <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
          <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
          <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
          <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
          <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
        </linearGradient>
        <path className="st3-10" d="M381,318.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C380.1,314.2,379.7,317.9,381,318.6z"/>

        <linearGradient id="SVGID_3_10" gradientUnits="userSpaceOnUse" x1="2737.824" y1="-943.9894" x2="2781.1619" y2="-943.9894" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
          <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
          <stop  offset="0" style={{stopColor: "#FFC600"}}/>
          <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
          <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
          <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
          <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
          <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
          <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
          <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
          <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
          <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
          <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
          <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
          <stop  offset="1" style={{stopColor: "#C8812C"}}/>
        </linearGradient>
        <path className="st4-10" d="M381,318.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C380.1,314.2,379.7,317.9,381,318.6z"/>

        <radialGradient id="SVGID_4_10" cx="2759.3647" cy="-996.2197" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
          <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
          <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
          <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
          <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
          <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
          <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
          <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
          <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
          <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
          <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
          <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
          <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
          <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
        </radialGradient>
        <circle className="st5-10" cx="401.5" cy="259.9" r="14.3"/>
    </g>
  <g className="arrow">
    <path className="st6" d="M409.3,238.2H393c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								V223C424.5,231.4,417.7,238.2,409.3,238.2z"/>
    <path className="st7" d="M409.3,236.2H393c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								V221C424.5,229.4,417.7,236.2,409.3,236.2z"/>
    <path className="st7" d="M408,237l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C409.6,232.7,409.6,235.4,408,237z"/>
    <path className="st6" d="M402.8,228.1l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C402.1,228.6,402.5,228.4,402.8,228.1z"/>
  </g>
</g>
    </>
  );
};

export default IrelandSVG;
