import React from 'react';

const BulgariaSVG = () => {

  const css = `.st2-15{fill:url(#SVGID_1_15);}
.st3-15{fill:url(#SVGID_2_15);}
.st4-15{fill:url(#SVGID_3_15);}
.st5-15{fill:url(#SVGID_4_15);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <polygon className="st0" points="1193.2,770.6 1190.2,765.2 1187,761.4 1184.1,755.9 1183.9,752.8 1185,749.7 1186.7,747.4
							1190.2,745.5 1195.6,744.3 1198.8,743.9 1202.2,744.9 1205,747 1208.9,751.1 1210.5,753.7 1212,755.3 1215,756.9 1222.2,758.9
							1240.3,764.9 1247.7,766.4 1253,767.9 1263.4,770 1274.5,772.8 1284.4,776.2 1287.9,777.5 1295,778.4 1302.4,778.3 1308.7,777.5
							1314.8,775.7 1317.9,774.7 1322.5,774 1331,774.3 1340.7,775.9 1353.5,778.7 1367.1,782.9 1382.7,788.9 1385.6,791.3 1391,796.2
							1399.1,801.1 1404.3,804.2 1404.4,805.2 1403,807.3 1399.4,808 1394.5,808.5 1390.1,808.8 1384.7,810.9 1368.7,818.1 1364,820.8
							1359.9,821.5 1357,821.5 1354.7,822.9 1352.9,826.6 1352.7,831.1 1354.7,834.9 1357.8,839.6 1360.7,842.7 1361.6,845.4 1360.7,848
							1358.2,849.5 1355.1,849.8 1350.5,849.1 1347.4,846.9 1339.4,844 1329.3,840.8 1320.1,838.7 1313.5,838 1309.5,838 1308,838.9
							1307,840.9 1306.8,843.7 1305.8,844.8 1302.2,844 1297.6,844 1291,844 1288.6,844.9 1287.4,846.6 1286.7,847.9 1285,849.1
							1282.7,850 1278.4,850.4 1272.9,849.2 1263.7,847.3 1252.7,843.1 1240.7,839.3 1236.4,836.9 1231.3,834.7 1225.8,832.9
							1216.1,830.1 1208.1,828.5 1196,826.3 1189.3,824.9 1187.4,823.9 1186.1,821.4 1183.9,818.9 1182.1,816.8 1182.1,813.7
							1180.1,810.6 1176.4,804.8 1170.7,796.9 1169.2,795.1 1169.5,792.6 1172,789.4 1173.3,785.5 1177.3,780.2 1182.4,777.7
							1185.6,776.8 1189,777.8 1191.9,778.7 1193.9,777.2 1194.7,775.1 1194.4,772.3 1193.5,771 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1237.1" cy="804.3" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_15" gradientUnits="userSpaceOnUse" x1="1904.8303" y1="-487.6392" x2="1948.1333" y2="-487.6392" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-15" d="M1216,804.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1215.1,799.9,1214.7,803.6,1216,804.3z"/>

          <linearGradient id="SVGID_2_15" gradientUnits="userSpaceOnUse" x1="1926.1208" y1="-498.4634" x2="1926.4515" y2="-454.8568" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-15" d="M1216,804.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1215.1,799.9,1214.7,803.6,1216,804.3z"/>

          <linearGradient id="SVGID_3_15" gradientUnits="userSpaceOnUse" x1="1904.8303" y1="-454.7954" x2="1948.1681" y2="-454.7954" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-15" d="M1216,804.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1215.1,799.9,1214.7,803.6,1216,804.3z"/>

          <radialGradient id="SVGID_4_15" cx="1926.371" cy="-507.0257" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-15" cx="1236.5" cy="745.6" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1244.3,723.9H1228c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1259.5,717.1,1252.7,723.9,1244.3,723.9z"/>
          <path className="st7" d="M1244.3,721.9H1228c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1259.5,715.1,1252.7,721.9,1244.3,721.9z"/>
          <path className="st7" d="M1243,722.7l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1244.6,718.4,1244.6,721.1,1243,722.7z"/>
          <path className="st6" d="M1237.8,713.8l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1237.1,714.3,1237.5,714.1,1237.8,713.8z"/>
        </g>
      </g>
    </>
  );
};

export default BulgariaSVG;
