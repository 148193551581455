import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './components/App';
import RootProvider from './context/root-context';
import Wrapper from "./components/Wrapper";

import { initializeGoogleAnalytics } from './helpers/gaHelper';


initializeGoogleAnalytics();

// entry point of the application
ReactDOM.render(
  <Wrapper>
    <React.StrictMode>
      <BrowserRouter>
        <Route path="/:country?/:category?">
          <RootProvider>
            <App />
          </RootProvider>
        </Route>
      </BrowserRouter>
    </React.StrictMode>
   </Wrapper>,
  document.getElementById('root')
);
