import React from 'react';

const FinlandSVG = () => {

  const css = `.st2-1{fill:url(#SVGID_1_1);}
               .st3-1{fill:url(#SVGID_2_1);}
               .st4-1{fill:url(#SVGID_3_1);}
               .st5-1{fill:url(#SVGID_4_1);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M1453.2,96l4.4-2.4l4.9-1.5l5.3-0.6l5.5,0.3l4.3,0.9l3.6,2.7l3.9,4.6l5.3,4.6l4.7,3.1l6.8,1.9l5.2,1.8l7.4,2.1
						l5,1.8l3.4,0.9l4.7-0.6l2.2-1.3l1.8-0.6l3.9,1.3l7,2.8l4.3,2.4l3.7-0.3l2.5-0.3l3.6,2.2l2.2,2.7l2.5,1.3l5-1.5c0,0,3.9-2.4,3.9-2.8
						s1.8-3,1.8-3l2.7-1.9l3,0.7c0,0,3.4,1.6,3.9,1.5s4.3,0.3,4.3,0.3l3.1-1.2l1.3-2.8l0.9-5.5c0,0,0.1-2.4,0.7-3s2.1-1.9,2.1-1.9
						l5.9-0.9l6.1-0.3l3.1-0.4l3.1-2.7l2.2-1l5-0.1l8.7,1.3l10.2,1.2l3.7-0.7l6.1-0.1l9.8,0.9l4.4,1.8l1.9,1.8l1.6,2.4
						c0,0,4.1,2.8,4.6,3.1s5.9,3.1,5.9,3.1l8.1,2.2l4.9,3.1l0.9,2.5l-0.9,3.4l-1.6,3.6l-3.1,1.5l-4.4,1c0,0-3.3,3.1-3,3.6
						c0.3,0.4-3.4,0.7-3.4,0.7l-6.2-0.9l-1.8,1.3l-1.3,3l-4.1,3.3l-3.7,1.3l-0.1,2.2l2.8,4.3l2.7,3.4l6.1,3l9,4.4l7.4,5.6l7.1,5.5v1.9
						l-1.6,2.7l-3.4,1.5l-9.3,2.2l-10.7,2.7l-6.4,1.5l-1.9,1.6l0.6,2.7l3.6,4.6l8.9,12.9l7.3,11.1l1,2.8l-0.3,1.6l-2.1,1l-4,0.1l-5.8,0.3
						l-4.4,1l-1.2,2.4l0.3,3.3l1.9,3l2.1,3.3l-0.7,1.2l-2.4,0.3l-3,0.1l-2.8,0.4l-0.3,1.3l3.3,2.4l8.4,3.7l1.2,1.2l-1.5,1.3l-2.8,1.3
						l-3,1.5l-0.6,2.1l1,1.8l8,5.3l3.3,2.7l0.7,2.1l-0.4,1.6l-1.6,1l-2.2,0.9l-3.9,0.4l-9.6,0.3l-2.5,0.1l-1.2,1.2v1.2l1.5,2.1l6.8,3.7
						l11.7,7.7l9,6.1l6.1,5.3l3.1,4.1l1.2,2.7l-0.6,2.7l-1.8,2.2c0,0-2.2,1.5-2.7,1.8s-4.1,1.8-4.1,1.8l-7.9,1.9l-4.9,0.9
						c0,0-14.2,2.4-14.8,2.7c-0.6,0.3-11.6,2.5-11.6,2.5l-4.4,1.2l-4.1,3.1l-5.3,2.2l-8.4,2.1l-8.9,0.7l-12.1,2.5l-7.7,0.9l-9.3,1.9
						l-7,1.3l-3.7,1.3l-4.9,3.1l-4.4,1.6l-2.8,0.3l-4.3-0.3l-9.2-1l-7.6-1.6l-7.7-0.4L1520,338l-12.3-1.2l-13-0.6l-20-1l-17.6-0.6
						l-12-0.9l-10.4-1l-7-1.2l-6.4-2.2l-5.2-3l-5.3-3l-3.1-0.7h-3.1l-4-0.6l-2.1-1.8l-4.4-3.7l-2.5-2.2l-3.9-0.3l-3.4-1l-4.7-2.5
						l-9.5-5.8l-4.4-2.5l-2.8-0.9l-0.7-1.5l2.4-2.1l9.2-6.8l8.7-5.6l2.4-3.6c0,0,0.1-4,0.1-4.6s-1.3-6.1-1.3-6.1l-1.2-2.4l0.9-2.8
						l2.5-3.1l0.1-3.6l-1.6-3.4c0,0-1.3-1.6-1.5-3.1c-0.1-1.5-0.1-3.7-0.1-3.7l2.7-1.3l5-0.7l3.3-2.4l5.5-3.6l2.4-0.7l4.1,0.1l5.8,1.2
						l8.3,0.6l4-0.3l2.4-1.3l2.8-3.3l3.1-2.8l3.6-1l4.3-0.6l8.3,0.7l3.7-0.4l4.9-1.8l3.7-2.1l2.8-0.3l7.3,0.7l7.6-0.7l7.7-1.3l8.1-2.2
						l7.6-3.4l6.1-4.6l3.6-3.1l3.9-1.5l6.2,0.1l2.8,1l2.7,2.1h1.6l1.9-1.6l2.5-6.4l0.1-4.4l-1-2.1l-3.4-2.8l-9.8-5l-8.3-3l-5.9-2.7
						l0.1-4.6l-0.6-3.9l-2.7-2.2l-2.5-3.7l0.3-2.4l2.4-0.4l3,0.6l3.3-1.9l3.6-3.3l0.1-4.9l-2.4-2.5l-2.7-2.4l-0.1-2.2l3-4.6l-0.4-2.8
						l-2.7-3.9l-0.9-2.7l1.9-3.7l4.3-4l0.6-3l-1.2-2.8l-4.9-3l-10.7-3.9l-14.8-6.2l-6.5-3.3l-7.3-4.9l-6.5-4.6l-4.1-2.7L1453.2,96z" />
      </g>

      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1542.3" cy="285.8" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_1" gradientUnits="userSpaceOnUse" x1="1597.5236" y1="-1004.9344" x2="1640.8265"
                          y2="-1004.9344"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-1" d="M1521.2,285.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1520.3,281.4,1519.9,285.1,1521.2,285.8z" />

          <linearGradient id="SVGID_2_1" gradientUnits="userSpaceOnUse" x1="1618.8142" y1="-1015.7587" x2="1619.1448"
                          y2="-972.152" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428" }} />
            <stop offset="0.2913" style={{stopColor: "#E99517" }} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-1" d="M1521.2,285.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1520.3,281.4,1519.9,285.1,1521.2,285.8z" />

          <linearGradient id="SVGID_3_1" gradientUnits="userSpaceOnUse" x1="1597.5236" y1="-972.0906" x2="1640.8615"
                          y2="-972.0906" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-1" d="M1521.2,285.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1520.3,281.4,1519.9,285.1,1521.2,285.8z" />

          <radialGradient id="SVGID_4_1" cx="1619.0642" cy="-1024.3209" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-1" cx="1541.7" cy="227.1" r="14.3" />
        </g>

        <g className="arrow">
          <path className="st6" d="M1549.5,205.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1564.7,198.6,1557.9,205.4,1549.5,205.4z" />
          <path className="st7" d="M1549.5,203.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1564.7,196.6,1557.9,203.4,1549.5,203.4z" />
          <path className="st7" d="M1548.2,204.2l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1549.8,199.9,1549.8,202.6,1548.2,204.2z" />
          <path className="st6" d="M1543,195.3l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1542.3,195.8,1542.7,195.6,1543,195.3z" />
        </g>
      </g>
    </>
  );
};

export default FinlandSVG;
