import { useContext } from 'react';
import { RootContext } from '../context/root-context';

const useCountryData = () => {
  const [state] = useContext(RootContext);

  return {
    countries: state.countries,
  };
};

export default useCountryData;
