import React from 'react';
import Country from './Country';
import Map from '../assets/img/map.png';
import Elements from '../assets/img/elements.png';
import useCountryData from '../hooks/useCountryData';
import useGame from '../hooks/useGame';

const CountryMap = () => {
  const { countries } = useCountryData();
  const { showQuestionPage } = useGame();

  // render output
  return (
    <section className="map-container">
      <div className="map">
        <img src={Map} alt="map" />
      </div>
      <div className="elements">
        <img src={Elements} alt="map-details" onClick={showQuestionPage}/>
      </div>
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1920" height="1108" viewBox="0 0 1920 1108">
          {countries && countries.map((country) => {
            return (
              <Country key={country.ID} data={country} />
            );
          })}
        </svg>
      </div>
    </section>
  );
};

export default CountryMap;
