import React from 'react';

const SlovakiaSVG = () => {

  const css = `.st2-20{fill:url(#SVGID_1_20);}
.st3-20{fill:url(#SVGID_2_20);}
.st4-20{fill:url(#SVGID_3_20);}
.st5-20{fill:url(#SVGID_4_20);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <polygon className="st0" points="1077.6,597.1 1076.1,594.3 1074.4,587.3 1073.9,582.7 1077,580.8 1080.6,578.4 1084,577.7
							1089.6,578.3 1100.3,578.9 1110.2,578.3 1115.7,577.2 1123.3,574.4 1131,571.4 1139.6,570.4 1145.5,569.7 1149,573.4 1152.6,575
							1162.2,574.4 1167,574.6 1169.5,575.4 1183.7,582.6 1188.4,583.6 1195.6,583.4 1199.7,583.4 1210.8,587.4 1215.7,589.8
							1221.5,590.7 1228,591.1 1239.6,595.3 1245.8,598.1 1249.2,601.1 1252.6,603.4 1258.7,605.7 1266.4,607.6 1264.6,608.8
							1259.7,610.3 1255.1,612.2 1251.4,613.7 1247.9,615.4 1244.1,618.4 1243.3,620.5 1243.3,623.1 1244.6,624.9 1245.9,627.7
							1247.7,629.8 1241,626.6 1236.6,625.2 1233.3,623.9 1229.8,621.4 1227.6,618.9 1224.1,616.6 1211.4,610.9 1201.5,607.9
							1198.4,606.6 1194.5,607.1 1185.3,608.9 1173.8,611.3 1169.6,612.3 1163.7,612.5 1155.6,611.6 1147.3,610.3 1135.6,609.4
							1129.9,609.4 1127,609.8 1125.3,611 1123.1,612.9 1120.4,613.2 1115,613.1 1103.4,612.3 1096.6,611 1089.9,608.6 1084,604.9
							1079.4,600.5 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1160.6" cy="597.2" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_20" gradientUnits="userSpaceOnUse" x1="1980.5348" y1="-695.1349" x2="2023.8378" y2="-695.1349" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-20" d="M1139.5,597.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1138.6,592.8,1138.2,596.5,1139.5,597.2z"/>

          <linearGradient id="SVGID_2_20" gradientUnits="userSpaceOnUse" x1="2001.8254" y1="-705.9591" x2="2002.1561" y2="-662.3524" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-20" d="M1139.5,597.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1138.6,592.8,1138.2,596.5,1139.5,597.2z"/>

          <linearGradient id="SVGID_3_20" gradientUnits="userSpaceOnUse" x1="1980.5348" y1="-662.2911" x2="2023.8727" y2="-662.2911" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-20" d="M1139.5,597.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1138.6,592.8,1138.2,596.5,1139.5,597.2z"/>

          <radialGradient id="SVGID_4_20" cx="2002.0756" cy="-714.5214" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-20" cx="1160" cy="538.5" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1167.8,516.8h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1183,510,1176.2,516.8,1167.8,516.8z"/>
          <path className="st7" d="M1167.8,514.8h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1183,508,1176.2,514.8,1167.8,514.8z"/>
          <path className="st7" d="M1166.5,515.6l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1168.1,511.3,1168.1,514,1166.5,515.6z"/>
          <path className="st6" d="M1161.3,506.7l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1160.6,507.2,1161,507,1161.3,506.7z"/>
        </g>
      </g>
    </>
  );
};

export default SlovakiaSVG;
