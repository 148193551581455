import React from 'react';
import { FormattedMessage } from 'react-intl'
import IconClose from '../assets/img/icons/icon_close.svg';
import useGame from '../hooks/useGame';

const MiniRulesPage = () => {

  const { hideMiniRulesPage } = useGame();

  // render output
  return (
    <div className="the-rules">
      <div className="inner">
      <FormattedMessage id="rulesText" defaultMessage="reeglid" values={{
          p: content => <p>{content}</p>,
          span: content => <span>{content}</span>,
          ul: content => <ul>{content}</ul>,
          li: content => <li>{content}</li>,
          strong: content => <strong>{content}</strong>,
          h1: content => <h1>{content}</h1>,
          h2: content => <h2>{content}</h2>,
          mailto: content => <a href="mailto:COMM-REP-TLL@ec.europa.eu">{content}</a>,
          br: () => <br></br>,
        }}/>

      </div>
      <div className="close">
        <span onClick={() => hideMiniRulesPage()} ><FormattedMessage id="close" defaultMessage="Sulge"/></span>
        <img src={IconClose} className="close-icon" onClick={() => hideMiniRulesPage()} alt="Sulge" />
      </div>
    </div>
  );
};

export default MiniRulesPage;
