import React from 'react';

const PolandSVG = () => {

  const css = `.st2-6{fill:url(#SVGID_1_6);}
               .st3-6{fill:url(#SVGID_2_6);}
               .st4-6{fill:url(#SVGID_3_6);}
               .st5-6{fill:url(#SVGID_4_6);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M1069.9,432l2.7,1.1h1.9l3.6-0.4l2.2-1.5l2.4-1.3l2.5-1l4.6,0.3l13.3,1.2l11.6,1.3l5.6,0.4l7.9-0.9l8.3-1.5
							l6.7-1l9.2-0.9l9-0.1l20,0.6l16.4,1.5l9,0.9l1.5,1.2l-0.6,1.6l-0.4,3.6l0.4,3.6l1.8,3l3.7,2.5l4.4,1.3l6.4,0.6l4.6-0.4l6.5-1.5
							l2.5-0.9l3,1.3l9.6,4.4l13,4.1l19.7,5.2l13.5,3.7l12.7,3.3l11,1.9l8.6,1.3l4.1,0.5l11.7,4.3l6.4,3.4l3.1,2.9l1.6,3.2l-2.2,2.1
							l-2.5,2.7l-0.9,2.1l-0.4,3.4l0.6,3l2.8,3.4l1.5,2.4l1,4l-1.2,3.6l-1.6,3.4l-1.9,2.1l-3,1.6l-5.8,0.4l-7.1,1l-7.9,1.8l-3.1,1.9
							l-0.6,1.5l0.4,1.8l4.6,3.7l2.1,2.5l0.1,2.4l-1.3,2.8l-2.8,3.7l-1.5,3.4l-1.3,5v3.3l1.6,4l2.8,5l2.1,4.9l0.4,5.2l-0.1,2.2l-1.6,1.9
							l-2.2,1.2l-3.4,1l-6.2,0.3l-4.4,0.6l-5.2,2.1l-27.7,8.6l-6.8,1.6l-2.2,2.2l-0.6,3.9l-0.6,4l-1.2,2.8l-1.9,2.4l-1.9-0.3l-5.3-1.5
							l-6.5-2.4l-3.4-2.4l-3.4-3l-6.2-2.8l-11.6-4.1l-6.5-0.4l-5.8-0.9l-4.9-2.4c0,0-10.7-4.1-11.1-4s-4.1,0-4.1,0l-7.1,0.1l-4.7-1
							l-5.2-2.7l-9-4.4l-2.5-0.9l-4.7-0.1l-9.6,0.6l-3.6-1.6l-3.6-3.7l0.4-1.3l-3.9-9.6l-1.9-1.8l-13.9-3.9l-3.7-1.8l-1.3-2.1v-4.7
							l-1.5-2.1l-15.3-5.8l-3.6,0.1l-7,3.6h-2.4l-3.1-1.9l-4.6-4.4l-1-2.8l-0.6-3.3l-2.5-2.8l-15.7-8.9l-12.3-9l4.7-7l-0.9-2l-1.5-2
							l-0.4-2.1l-0.1-3.9l1.1-5.3l3.3-7.2l-0.3-2.7l-1-3.1l1.9-4.2l0.4-2.4l-0.4-1.9l-1.9-2.1l-2.1-2.1l-1.6-1.6l-0.4-1l0.4-1.1l1.9-1.4
							l2.1-1.6l3.2-1.6l2.7-2.4l2.8-3.2l1.6-3.5l1.8-4l0.5-2.7L1069.9,432z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1151.3" cy="503.3" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_6" gradientUnits="userSpaceOnUse" x1="1989.4089" y1="-789.0602" x2="2032.7119"
                          y2="-789.0602" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-6" d="M1130.2,503.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1129.3,498.9,1128.9,502.6,1130.2,503.3z" />

          <linearGradient id="SVGID_2_6" gradientUnits="userSpaceOnUse" x1="2010.6995" y1="-799.8844" x2="2011.0302"
                          y2="-756.2777" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-6" d="M1130.2,503.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1129.3,498.9,1128.9,502.6,1130.2,503.3z" />

          <linearGradient id="SVGID_3_6" gradientUnits="userSpaceOnUse" x1="1989.4089" y1="-756.2164" x2="2032.7467"
                          y2="-756.2164" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-6" d="M1130.2,503.3c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1129.3,498.9,1128.9,502.6,1130.2,503.3z" />

          <radialGradient id="SVGID_4_6" cx="2010.9496" cy="-808.4466" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-6" cx="1150.7" cy="444.6" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M1158.5,422.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1173.7,416.1,1166.9,422.9,1158.5,422.9z" />
          <path className="st7" d="M1158.5,420.9h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1173.7,414.1,1166.9,420.9,1158.5,420.9z" />
          <path className="st7" d="M1157.2,421.7l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1158.8,417.4,1158.8,420.1,1157.2,421.7z" />
          <path className="st6" d="M1152,412.8l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1151.3,413.3,1151.7,413.1,1152,412.8z" />
        </g>
      </g>
    </>
  );
};

export default PolandSVG;
