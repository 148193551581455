import React from 'react';

const RomaniaSVG = () => {

  const css = `.st2-16{fill:url(#SVGID_1_16);}
.st3-16{fill:url(#SVGID_2_16);}
.st4-16{fill:url(#SVGID_3_16);}
.st5-16{fill:url(#SVGID_4_16);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M1152.6,675.6l1.6,2.9l2.6,4.6l2.7,4.1l0.7,1.6l-0.3,2.1l-1.1,1.4c0,0-0.4,1.2-0.4,1.4s0.6,2.6,0.6,2.6l1.6,2.4
							l2.4,1.6l4.2,0.7l2.6,1l0.4,1.8l-1,2.4l-1.9,2.6l-3.1,3.4l-0.5,1.9l0.4,1.3l1.6,1.5l9.7,3.4l13.9,4.6l7.5,3.5l2.7,1.9
							c0,0,0.7,1.1,0.7,1.3s-0.2,3.3-0.2,3.3l-0.8,3.6l-0.2,2.8l-0.3,2.7l3.4,1l2.8,2.1l3.9,4.1l1.6,2.5l1.5,1.6l3,1.6l7.3,1.9l18.1,6.1
							l7.4,1.5l5.3,1.5l10.4,2.1l11.1,2.8l9.9,3.4l3.4,1.3l7.1,0.9l7.4-0.1l6.4-0.7l9.2-2.8l4.6-0.7l8.4,0.3l9.8,1.6l12.7,2.8l13.6,4.1
							l15.6,6.1l3,2.4l5.3,4.9l13.3,8l0.1,1l1.3-1.5l1-2.7l1.4-3.9l1.6-5.3l2-3.6l2.6-3l4.3-3.9l2.9-2.1l2.9-1.3l4.8-0.9l3.3,0.2l6.9,0.9
							l4.2,0.5l4.1,0.2l2.8-0.5l2.8-1.3l2.3-1.9l1.7-2.3l0.8-3V768l-1-2.4l-2.4-3.1l-3-2.4l-3-1.2l-2.7-0.6l-3-0.1l-4.7,0.9l-4.1,0.7h-3.6
							l-3.4,0.1l-3-0.6l-3.9-1.6l-3.5-2.6l-2.1-1.9l-1-1.6l0.1-1.5l-0.1-1.9c0,0-1.3-3-1.4-3.6c-0.1-0.5-0.7-3.6-0.6-4s0.1-3.6,0.1-3.6
							l0.7-4.4l1.4-3.5l2-2.6l3-2.9l2.8-1.9l1.3-1.3c0,0,0.4-1.7,0.4-2s-0.1-3.3-0.1-3.3l-0.5-3.6l-1.6-3.9l-3.7-7.4l-6.6-11.2l-6.4-12
							l-4.7-11.5l-2.1-5.3l-1-1.8l-2.4-0.9l-6.5,0.1l-7,0.7l-3.5,0.9l-2.2,1.3l-3.9,1.3l-3.4,0.4l-4.7,0.4l-4.7,0.1l-3.2-0.7l-3.6-1.3
							l-4.2-1.7l-1.6-0.9l-3.6-0.2l-2,0.2l-3.1,1l-2.8,1.1l-4.3,0.5l-2.7-0.2l-2.4-0.5l-1.8-1.1l-2.4-1.6l-2.3-1.6l-2.3-1.3l-3.2-1.3
							l-4.7-1.9l-7-1.9l-9.3-3.1l-9-3.6l-9.1-3.4l-4.7-1.1l-3.1-0.5l-3.7-0.4l-2.4,0.1l-2.2,0.5l-1.6,2.1l-22.4,4l-8.7,1l-3.1,1.5
							l-6.2,3.7l-8.1,4.4l-10.2,6.2l-11.6,6.8l-9.3,3.9l-13.3,1.9l-7.6,0.7L1152.6,675.6z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1296.5" cy="716.9" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_16" gradientUnits="userSpaceOnUse" x1="1845.1024" y1="-574.8017" x2="1888.4054" y2="-574.8017" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-16" d="M1275.4,716.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1274.5,712.5,1274.1,716.2,1275.4,716.9z"/>

          <linearGradient id="SVGID_2_16" gradientUnits="userSpaceOnUse" x1="1866.3931" y1="-585.6259" x2="1866.7236" y2="-542.0192" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-16" d="M1275.4,716.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1274.5,712.5,1274.1,716.2,1275.4,716.9z"/>

          <linearGradient id="SVGID_3_16" gradientUnits="userSpaceOnUse" x1="1845.1024" y1="-541.9578" x2="1888.4403" y2="-541.9578" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-16" d="M1275.4,716.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1274.5,712.5,1274.1,716.2,1275.4,716.9z"/>

          <radialGradient id="SVGID_4_16" cx="1866.6431" cy="-594.1881" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-16" cx="1295.9" cy="658.2" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1303.7,636.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V605c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1318.9,629.7,1312.1,636.5,1303.7,636.5z"/>
          <path className="st7" d="M1303.7,634.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V603c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1318.9,627.7,1312.1,634.5,1303.7,634.5z"/>
          <path className="st7" d="M1302.4,635.3l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1304,631,1304,633.7,1302.4,635.3z"/>
          <path className="st6" d="M1297.2,626.4l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1296.5,626.9,1296.9,626.7,1297.2,626.4z"/>
        </g>
      </g>
    </>
  );
};

export default RomaniaSVG;
