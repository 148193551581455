import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { FacebookShareButton } from 'react-share';
import { FormattedMessage, useIntl } from 'react-intl'

import useGame from '../hooks/useGame';

import IconFacebook from '../assets/img/icons/facebook.svg';
import IconClose from '../assets/img/icons/icon_close.svg';

const ContactPage = () => {

  const intl = useIntl();
  const { REACT_APP_API_URL, REACT_APP_ENVIRONMENT } = process.env;

  const base = window.location.href.split('?')[0];
  const API_URL = REACT_APP_ENVIRONMENT === 'production' ? `${base}wp/?rest_route=/game` : REACT_APP_API_URL;


  const { visitedCountries, stopGame, questionsSeen, showRulesPage, checkAccess, showFeedbackPage } = useGame();

  const [student, setStudent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [county, setCounty] = useState('');
  const [teacher, setTeacher] = useState('');
  const [school, setSchool] = useState('');
  const [className, setClassName] = useState('');
  const [terms, setTerms] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [error, setError] = useState(false);
  const [errorField, setErrorField] = useState(false);

  // fix for Chrome autofill issue
  const emailField = useRef(null);
  const nameField = useRef(null);
  const countyField = useRef(null);
  const teacherField = useRef(null);
  const schoolField = useRef(null);
  const classField = useRef(null);

  useEffect(() => {
    let interval = setInterval(() => {
      if (emailField.current) {
        setEmail(emailField.current.value);
        setName(nameField.current.value);
        setCounty(countyField.current.value);

        clearInterval(interval);
      }
    }, 100);
  });

  useEffect(() => {
    let interval = setInterval(() => {
      if (teacherField.current) {
        setTeacher(teacherField.current.value);
        setSchool(schoolField.current.value);
        setClassName(classField.current.value);
      }

      clearInterval(interval);
    }, 100);
  });

  const submitForm = async (e) => {
    e.preventDefault();

    const canStart = await checkAccess();

    if (canStart) {

      const payload = {
        student: student ? 1 : 0,
        name,
        email,
        teacher_name: teacher,
        school,
        county,
        class: className
      };

      try {
        const response = await axios.post(`${API_URL}/contact`, payload);

        if (response.data) {

          setName('');
          setEmail('');
          setTeacher('');
          setSchool('');
          setCounty('');
          setClassName('');
          setTerms(false);
          setSubmittedForm(true);

          // stopGame();
        }
      } catch (error) {
        setError(true);

        if (error.response && error.response.data) {
          setErrorField(error.response.data.field);
        }
      }
    }
  };

  // render output
  return (
    <div className="end active">
      <div className="flex-container">

        <div className="layout-container">
          <div className="container">
            <div className="flex-container">

              <div className="visited">

                <div className="top">
                  <FormattedMessage id="goodJob" defaultMessage="<h2>Hea töö, reisisell!</h2>
                  <p>Jõudsid {questionsSeen} küsimusega külastada {numCountries} riiki ehk <br></br>
                    täpselt nii palju oli sul õigeid vastuseid!</p>" values={{ 
                      questionsSeen,
                      numCountries: visitedCountries.length,
                      p: content => <p>{content}</p>,
                      h2: content => <h2>{content}</h2>,
                      br: () => <br></br>}} />
                </div>

                <div className="visited-countries">
                  <div className="inner-wrap">
                    <h3><FormattedMessage id="visitedCountries" defaultMessage="Külastasid neid riike:"/></h3>
                    <div className="list">
                      {visitedCountries.map((country) => {
                        return <div key={country.name} className="flag-img"><img src={country.flag} className="small"
                                                                                 alt={country.name} />
                        </div>;
                      })}
                    </div>
                  </div>
                </div>
                <div className="feedback">
                <FormattedMessage id="opinionMatters" defaultMessage="Sinu arvamus on meile tähtis."/> <span onClick={() => showFeedbackPage()}><FormattedMessage id="howDidYouLike" defaultMessage="Kuidas Sulle mäng meeldis?"/></span>
                </div>

              </div>

              <div className="form finished">

                {!submittedForm && (
                  <>
                    <div className="form-top">
                    <FormattedMessage id="winPrizes" defaultMessage="<h1>Osale loosimises</h1>
                      <p>
                        Auhinnad loosime välja nädala jooksul pärast mängu lõppu. Peaauhinnad
                        on <strong>tahvelarvuti</strong> ja <strong>matk Eestimaa looduses</strong>, lisaks loosime
                        välja väiksemaid auhindu. Täida väljad ning looda heale õnnele.
                      </p>
                      <notice>
                        Kui Sul ei õnnestu end mingil põhjusel loosimisele kirja panna, siis saada loosis osalemiseks
                        oma nimi, e-posti aadress ja maakond e-posti aadressile <mailto>elis.paemurd@ec.europa.eu</mailto>.
                      </notice>" values={{
                        p: content => <p>{content}</p>,
                        strong: content => <strong>{content}</strong>,
                        h1: content => <h1>{content}</h1>,
                        notice: content => <p className="notice">{content}</p>,
                        mailto: content => <a href="mailto:elis.paemurd@ec.europa.eu">{content}</a>
                      }} />
                    </div>

                    <div className="the-form">

                      <form autoComplete="off">

                        <div className="field-switch">
                  <span className={classNames({
                    btn: true,
                    small: true,
                    white: student,
                    groupSwitch: student,
                    singleSwitch: !student,
                    selected: !student,
                  })} onClick={() => setStudent(false)}><FormattedMessage id="participate" defaultMessage="Osale loosimises" /></span>
                          <span className={classNames({
                            btn: true,
                            small: true,
                            white: !student,
                            groupSwitch: !student,
                            singleSwitch: student,
                            selected: student,
                          })} onClick={() => setStudent(true)}><FormattedMessage id="participateWithClass" defaultMessage="Osale loosimises klassiga" /></span>
                        </div>

                        <div className="fields-set">

                          <div className="fields single selected">
                            <div className={classNames({
                              'field-row': true,
                              error: errorField === 'name'
                            })}>
                              <input type="text" name="name" placeholder={ intl.formatMessage({"id":"fullName", "defaultMessage": "Ees- ja Perekonnanimi"}) } value={name}
                                     ref={nameField}
                                     onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className={classNames({
                              'field-row': true,
                              error: errorField === 'email'
                            })}>
                              <input type="email" name="email" placeholder={ intl.formatMessage({"id":"mailAddress", "defaultMessage": "E-maili aadress"}) } value={email}
                                     ref={emailField}
                                     onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className={classNames({
                              'field-row': true,
                              error: errorField === 'county'
                            })}>
                              <input type="text" name="county" placeholder={ intl.formatMessage({"id":"county", "defaultMessage": "Maakond" }) } value={county} ref={countyField}
                                     onChange={(e) => setCounty(e.target.value)} />
                            </div>

                            {student && (
                              <>
                                <div className={classNames({
                                  'field-row': true,
                                  error: errorField === 'teacher_name'
                                })}>
                                  <input type="text" name="teacher" placeholder={ intl.formatMessage({"id":"teacherName", "defaultMessage": "Klassijuhataja ees-ja perekonnanimi" }) }
                                         ref={teacherField}
                                         value={teacher} onChange={(e) => setTeacher(e.target.value)} />
                                </div>
                                <div className={classNames({
                                  'field-row': true,
                                  error: errorField === 'school'
                                })}>
                                  <input type="text" name="school" placeholder={ intl.formatMessage({"id":"school", "defaultMessage": "Kool"}) } value={school} ref={schoolField}
                                         onChange={(e) => setSchool(e.target.value)} />
                                </div>
                                <div className={classNames({
                                  'field-row': true,
                                  error: errorField === 'class'
                                })}>
                                  <input type="text" name="class" placeholder={ intl.formatMessage({"id":"class", "defaultMessage": "Klass"}) } value={className} ref={classField}
                                         onChange={(e) => setClassName(e.target.value)} />
                                </div>
                              </>
                            )}
                          </div>

                        </div>

                        <div className="terms">
                          <label htmlFor="rules" className="custom-radio">
                            <input type="checkbox" id="rules" name="accept-conditions" checked={terms}
                                   onChange={() => setTerms(!terms)} />
                            <span className="custom-button" />
                            <span className="text"><FormattedMessage id="agreeTerms" defaultMessage="Annan loa oma isikuandmete töötlemiseks" /><br />
                            <FormattedMessage id="andAgree" defaultMessage="ja nõustun" /> <a href="#" onClick={() => showRulesPage()}><FormattedMessage id="gameRules" defaultMessage="mängu reeglitega" /></a></span>
                          </label>
                        </div>

                        <div className="submit-container">
                          <button className="btn submit" type="submit" disabled={!terms}
                                  onClick={(e) => submitForm(e)}><FormattedMessage id="iParticipate" defaultMessage="Osalen" />
                          </button>
                          {error && (<p className="error-notice"><FormattedMessage id="fillInFields" defaultMessage="Palun täida kõik märgitud väljad!" /></p>)}
                        </div>

                      </form>

                    </div>
                  </>
                )}
                {submittedForm && (
                  <div className="form-message">
                    <span><FormattedMessage id="thanksForRegister" defaultMessage="Täname registreerimast." /></span>
                    <p><FormattedMessage id="youAreParticipating" defaultMessage="Osaled loosimises!" /></p>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>

        <div className="share-container">
          <div className="container">
            <div className="flex-container">
              <div className="text">
                <h3><FormattedMessage id="howWellFriend" defaultMessage="Kui hästi su sõber Euroopat tunneb?" /></h3>
                <span><FormattedMessage id="shareWithFriends" defaultMessage="Jaga mängu ka sõpradega:" /></span>
              </div>
              <FacebookShareButton url={window.location.href}>
                <a href="#" className="btn fb"><img src={IconFacebook} /><span><FormattedMessage id="share" defaultMessage="Jaga" /></span></a>
              </FacebookShareButton>
            </div>
          </div>
        </div>

      </div>
      <div className="close">
        <span onClick={() => stopGame()}><FormattedMessage id="close" defaultMessage="Sulge" /></span>
        <img src={IconClose} className="close-icon" onClick={() => stopGame()} alt="close" />
      </div>
    </div>
  );
};

export default ContactPage;
