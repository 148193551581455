import React from 'react';

const CyprusSVG = () => {

  const css = `.st2-14{fill:url(#SVGID_1_14);}
.st3-14{fill:url(#SVGID_2_14);}
.st4-14{fill:url(#SVGID_3_14);}
.st5-14{fill:url(#SVGID_4_14);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M1682.4,932.6l7.6-5.6l2.7-0.9l4.7,0.1l3.9,1.5l3.7,2.1l3.4,0.4l3.9-1.6l2.7-4.3l1.3-5.6l1.3-1.9l2.2-0.6
							l2.4,0.7l5.6,2.2l5.6,0.6l13.2,1.2l12.9,0.3l13.9-2.1l10.8-3.6l3.7-1.6l1.3,0.9l0.3,3.4l-1.2,2.5l-3.1,1.8l-4.9,2.4l-2.8,3l-0.3,3.9
							l0.7,3.1l2.5,3l11.7,7.7l3.1,2.2l0.6,1.9l-0.4,2.1l-1.5,0.7l-2.5-0.6l-3.9,0.4l-3.4,0.1l-2.2-0.7l-3.3-3l-3.3-2.4h-3.9l-1.9,0.7
							l-3.6,2.5l-0.6,3l0.1,4.1l-0.7,4.6l-1.3,2.4l-3.6,3.9l-4.4,2.5l-10.7,6.8l-2.7,0.9l-4.7,0.1l-9.2,0.6l-5.3-0.1l-3.9,0.7
							c0,0-1.8,1.2-2.4,1.5s-3.1,1.6-3.1,1.6l-3.6-0.4l-3-1.2l-2.5,0.4l-2.8,0.6l-4.1,0.9l-2.4-2.1l-0.9-3.6l-2.2-0.6l-4.1,0.6l-4.1,4
							l-3.3,3.4l-2.5,0.6l-3.4-1.6l-3.6-3.7l-3.3-3.4l-4.9-3.4l-3.7-3.7l-1.6-3.7c0,0,0.1-3.3,0.3-3.7c0.1-0.4,1.9-3.4,1.9-3.4l1.2-4.9
							l0.9-5.2l1.9-5.3l0.3-4.7l0.1-3.6l1.6-2.5l1.6-0.9l1.8,0.7l2.2,2.7l1.3,1.3l3.7,0.9l2.4-1.3L1682.4,932.6z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1718.1" cy="954.6" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_14" gradientUnits="userSpaceOnUse" x1="1424.4545" y1="-335.4469" x2="1467.7574" y2="-335.4469" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-14" d="M1697,954.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1696.1,950.2,1695.7,953.9,1697,954.6z"/>

          <linearGradient id="SVGID_2_14" gradientUnits="userSpaceOnUse" x1="1445.7451" y1="-346.2711" x2="1446.0757" y2="-302.6644" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-14" d="M1697,954.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1696.1,950.2,1695.7,953.9,1697,954.6z"/>

          <linearGradient id="SVGID_3_14" gradientUnits="userSpaceOnUse" x1="1424.4545" y1="-302.6031" x2="1467.7922" y2="-302.6031" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-14" d="M1697,954.6c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1696.1,950.2,1695.7,953.9,1697,954.6z"/>

          <radialGradient id="SVGID_4_14" cx="1445.9951" cy="-354.8333" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-14" cx="1717.5" cy="895.9" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1725.3,874.2H1709c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								V859C1740.5,867.4,1733.7,874.2,1725.3,874.2z"/>
          <path className="st7" d="M1725.3,872.2H1709c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								V857C1740.5,865.4,1733.7,872.2,1725.3,872.2z"/>
          <path className="st7" d="M1724,873l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1725.6,868.7,1725.6,871.4,1724,873z"/>
          <path className="st6" d="M1718.8,864.1l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1718.1,864.6,1718.5,864.4,1718.8,864.1z"/>
        </g>
      </g>
    </>
  );
};

export default CyprusSVG;
