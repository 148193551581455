import { useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RootContext } from "../context/root-context";

// helpers
import gameStates from "../helpers/gameState";
import countryStates from "../helpers/countryState";
import order from "../helpers/countryOrderHelper";

const useGame = () => {
  const [state, setState] = useContext(RootContext);
  const {
    REACT_APP_ANIMATION_TIME,
    REACT_APP_ENVIRONMENT,
    REACT_APP_API_URL,
  } = process.env;
  const params = useParams();

  /**
   * Stops the game
   */
  const stopGame = () => {
    const countries = state.countries.map((country) => {
      return {
        ...country,
        activeState: countryStates.NONE,
      };
    });

    setState((currentState) => ({
      ...currentState,
      countries,
      gameState: gameStates.NOT_STARTED,
      showQuestion: false,
      questionsSeen: 0,
      visited: [],
      accessText: false,
    }));
  };

  /**
   * Starts the game
   */
  const startGame = () => {
    if (state.countries) {
      const isDebug = !!(
        params.country &&
        params.category &&
        REACT_APP_ENVIRONMENT !== "production"
      );

      let start = 17; // set Estonia active as first country
      let questionIndex = -1; // question index = 0

      // override for debug
      if (isDebug) {
        state.countries.forEach((country) => {
          if (country.name === params.country) {
            questionIndex = country.questions.findIndex((question) => {
              return question.category === params.category;
            });

            if (questionIndex > -1) {
              start = country.ID;
            }
          }
        });
      }

      let activeCountry = false;
      const countries = state.countries.map((country) => {
        // only randomize questions when we are not in debugging mode
        if (!isDebug) {
          for (let i = country.questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [country.questions[i], country.questions[j]] = [
              country.questions[j],
              country.questions[i],
            ];
          }
        }

        const isMatch = country.ID === start;
        country.questionIndex = 0;
        country.wrongAnswers = 0;

        // override active question when it's a valid debug
        if (isDebug && questionIndex > -1) {
          country.questionIndex = questionIndex;
        }

        if (isMatch) {
          activeCountry = country;
        }

        return {
          ...country,
          activeState: isMatch ? countryStates.ACTIVE : countryStates.NONE,
        };
      });

      setState((currentState) => ({
        ...currentState,
        activeCountry,
        countries,
        gameState: gameStates.STARTED,
        showQuestion: false,
        visited: [],
        questionsSeen: 0,
        showMiniRules: false,
      }));
    }
  };

  // wait
  async function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * Go to the previous question
   */
  const previousQuestion = async () => {
    // get the index of current country
    const index = order.findIndex((country) => {
      return state.activeCountry.ID === country.ID;
    });

    if (index === 0) {
      let activeCountry = false;
      const countries = state.countries.map((country) => {
        const isMatch = country.ID === state.activeCountry.ID;

        if (isMatch) {
          // update question index
          country.questionIndex++;
          country.wrongAnswers = country.wrongAnswers
            ? country.wrongAnswers + 1
            : 1;

          if (country.questionIndex >= country.questions.length) {
            country.questionIndex = 0;
          }

          activeCountry = country;
        }

        return country;
      });

      // we are first country. hide question for 2 seconds and try again
      setState((currentState) => ({
        ...currentState,
        countries,
        activeCountry,
        showQuestion: false,
      }));

      if (activeCountry.wrongAnswers === 3) {
        setState((currentState) => ({
          ...currentState,
          gameState: gameStates.FAILED,
        }));
      } else {
        await wait(REACT_APP_ANIMATION_TIME);
        // update state
        setState((currentState) => ({
          ...currentState,
          showQuestion: true,
          questionsSeen: state.questionsSeen + 1,
        }));
      }
    } else {
      let failed = false;
      // map through all countries and mark yourself as not yet started
      let countries = state.countries.map((country) => {
        const isMatch = country.ID === state.activeCountry.ID;

        if (isMatch) {
          // update question index
          country.questionIndex++;
          country.wrongAnswers = country.wrongAnswers
            ? country.wrongAnswers + 1
            : 1;
          if (country.wrongAnswers >= 3) {
            failed = true;
          }

          if (country.questionIndex >= country.questions.length) {
            country.questionIndex = 0;
          }
        }

        return {
          ...country,
          activeState: isMatch ? countryStates.NONE : country.activeState,
        };
      });

      // update state
      setState((currentState) => ({
        ...currentState,
        countries,
        showQuestion: false,
      }));

      if (failed) {
        setState((currentState) => ({
          ...currentState,
          gameState: gameStates.FAILED,
        }));
      } else {
        await wait(REACT_APP_ANIMATION_TIME);

        let activeCountry = false;
        countries = countries.map((country) => {
          const isMatch = country.ID === order[index - 1].ID;
          if (isMatch) {
            activeCountry = country;
          }

          return {
            ...country,
            activeState: isMatch ? countryStates.ACTIVE : country.activeState,
          };
        });

        // and update the state
        setState((currentState) => ({
          ...currentState,
          activeCountry,
          countries,
          showQuestion: true,
          questionsSeen: state.questionsSeen + 1,
        }));
      }
    }
  };

  /**
   * Go to the next question
   */
  const nextQuestion = async () => {
    // get the next country
    const index = order.findIndex((country) => {
      return state.activeCountry.ID === country.ID;
    });

    const visited = state.visited;

    // map through all countries and mark yourself as done
    let countries = state.countries.map((country) => {
      const isMatch = country.ID === state.activeCountry.ID;

      if (isMatch) {
        country.questionIndex++;

        if (country.questionIndex >= country.questions.length) {
          country.questionIndex = 0;
        }

        // add this country to the visited list if it's not there
        // yes we have the
        const exists = visited.find((visitedCountry) => {
          return country.ID === visitedCountry.ID;
        });

        if (!exists) {
          visited.push(country);
        }
      }

      return {
        ...country,
        activeState: isMatch ? countryStates.DONE : country.activeState,
      };
    });

    // update state and mark yourself as done
    setState((currentState) => ({
      ...currentState,
      countries,
      showQuestion: false,
      visited,
    }));

    let nextCountry = false;
    if (index + 2 < order.length) {
      nextCountry = index + 2;

      // wait 2 seconds
      await wait(REACT_APP_ANIMATION_TIME);

      // we can move two steps. so, mark next country as done
      countries = countries.map((country) => {
        const isMatch = country.ID === order[index + 1].ID;
        return {
          ...country,
          activeState: isMatch ? countryStates.DONE : country.activeState,
        };
      });

      // and update the state
      setState((currentState) => ({
        ...currentState,
        countries,
      }));
    } else if (index + 1 < order.length) {
      // we can move one country forward
      nextCountry = index + 1;
    }

    if (nextCountry) {
      let activeCountry = false;
      countries = countries.map((country) => {
        const isMatch = country.ID === order[nextCountry].ID;

        if (isMatch) {
          activeCountry = country;
        }

        return {
          ...country,
          activeState: isMatch ? countryStates.ACTIVE : country.activeState,
        };
      });

      // wait
      await wait(REACT_APP_ANIMATION_TIME);

      setState((currentState) => ({
        ...currentState,
        activeCountry,
        countries,
        showQuestion: true,
        questionsSeen: state.questionsSeen + 1,
      }));
    } else {
      // we finished the game!
      finishGame();
    }
  };

  /**
   * Finishes the game
   */
  const finishGame = () => {
    setState((currentState) => ({
      ...currentState,
      gameState: gameStates.FINISHED,
    }));
  };

  const showFeedbackPage = () => {
    setState((currentState) => ({
      ...currentState,
      showFeedback: true,
    }));
  };
  const hideFeedbackPage = () => {
    setState((currentState) => ({
      ...currentState,
      showFeedback: false,
    }));
  };

  /**
   * Shows rules page
   */
  const showRulesPage = () => {
    setState((currentState) => ({
      ...currentState,
      showRules: true,
    }));
  };

  /**
   * Hides rules page
   */
  const hideRulesPage = () => {
    setState((currentState) => ({
      ...currentState,
      showRules: false,
    }));
  };

  /**
   * Shows mini rules page
   */
  const showMiniRulesPage = () => {
    setState((currentState) => ({
      ...currentState,
      showMiniRules: true,
    }));
  };

  /**
   * Hides rules page
   */
  const hideMiniRulesPage = () => {
    setState((currentState) => ({
      ...currentState,
      showMiniRules: false,
    }));
  };

  /**
   * Shows the question page
   */
  const showQuestionPage = async () => {
    setState((currentState) => ({
      ...currentState,
      showQuestion: true,
      showQuestionActive: false,
    }));

    await wait(200);

    setState((currentState) => ({
      ...currentState,
      showQuestion: true,
      showQuestionActive: true,
    }));
  };

  /**
   * Hides the question page
   */
  const hideQuestionPage = () => {
    setState((currentState) => ({
      ...currentState,
      showQuestion: false,
      showQuestionActive: false,
    }));
  };

  const hideAccessText = () => {
    setState((currentState) => ({
      ...currentState,
      accessText: false,
    }));
  };

  /**
   *
   */
  const checkAccess = async () => {
    const response = await axios.get(`${REACT_APP_API_URL}/access`);

    if (response.data) {
      const { access, reason } = response.data;

      let accessText = false;

      if (!access && reason) {
        if (reason === "early") {
          accessText = "Mäng algab 26. august 09:00, palun varu veidi kannatust.";
        } else {
          accessText = "Mängu aeg on läbi, kahjuks enam mängida ei saa.";
        }
      }

      // accessText = 'Mäng algab 09:00, palun varu veidi kannatust.';

      setState((currentState) => ({
        ...currentState,
        accessText,
      }));

      return accessText === false;
    }

    return false;
  };

  return {
    gameState: state.gameState,
    showRules: state.showRules,
    showMiniRules: state.showMiniRules,
    showFeedback: state.showFeedback,
    activeCountry: state.activeCountry,
    showQuestion: state.showQuestion,
    showQuestionActive: state.showQuestionActive,
    visitedCountries: state.visited,
    questionsSeen: state.questionsSeen + 1,
    outOfTime: state.outOfTime,
    accessText: state.accessText,
    startGame,
    finishGame,
    showRulesPage,
    hideRulesPage,
    showMiniRulesPage,
    hideMiniRulesPage,
    showQuestionPage,
    hideQuestionPage,
    nextQuestion,
    previousQuestion,
    stopGame,
    checkAccess,
    hideAccessText,
    showFeedbackPage,
    hideFeedbackPage,
  };
};

export default useGame;
