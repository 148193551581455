import React from "react";
import { FormattedMessage } from "react-intl";

import useGame from "../hooks/useGame";

import SadFace from "../assets/img/sadface.png";
import IconClose from "../assets/img/icons/icon_close.svg";

const ContactPage = () => {

  const { startGame, stopGame } = useGame();

  // render output
  return (
    <div className="failed failed-page active">
      <div className="flex-container">
        <div className="layout-container">
          <div className="container">
            <div className="flex-container flex-wrap">

              <img src={SadFace} alt=":(" />

              <div class="failed-info">
                <FormattedMessage
                  id="failedText"
                  defaultMessage="Kahjuks seekord ei õnnestunud."
                />
              </div>
              <div className="submit-container">
                <button
                  className="btn submit"
                  type="button"
                  onClick={startGame}
                >
                  <FormattedMessage
                    id="failedBtn"
                    defaultMessage="Proovi uuest!"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="close">
        <span onClick={() => stopGame()}>
          <FormattedMessage id="close" defaultMessage="Sulge" />
        </span>
        <img
          src={IconClose}
          className="close-icon"
          onClick={() => stopGame()}
          alt="close"
        />
      </div>
    </div>
  );
};

export default ContactPage;
