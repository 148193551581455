import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useGame from '../hooks/useGame';
import BtnStartNormal from '../assets/img/icons/btn_start_normal.png';
import BtnStartHover from '../assets/img/icons/btn_start_hover.png';
import BtnStartNormalRu from '../assets/img/icons/btn_start_normal_ru.png';
import BtnStartHoverRu from '../assets/img/icons/btn_start_hover_ru.png';
import LogoEU from '../assets/img/logo_eu.png';

const FrontPage = () => {

  const { startGame, checkAccess } = useGame();

  const tryToStartGame = async () => {
    const canStart = await checkAccess();

    if(canStart) {
      startGame();
    }
  }

  // render output
  return (
    <div className="intro">
      <div className="container">

        <div id="start" className="start">
          <img className="bottom" src={useIntl().locale === 'ru' ? BtnStartNormalRu : BtnStartNormal} onClick={() => tryToStartGame()} alt="Start Game" />
          <img className="top" src={useIntl().locale === 'ru' ? BtnStartHoverRu : BtnStartHover} onClick={() => tryToStartGame()} alt="Start Game" />
        </div>

        <div className="flex-container">
          <div className="eu-info">
            <h3><FormattedMessage id="eUnion" defaultMessage="Euroopa Liit"/></h3>
            <ul>
              <li><label><FormattedMessage id="area" defaultMessage="Pindala:"/></label><span>4 233 255 km<sup>2</sup></span></li>
              <li><label><FormattedMessage id="population" defaultMessage="Elanike arv:"/></label><span>447 <FormattedMessage id="million" defaultMessage="miljonit"/></span></li>
              <li><label><FormattedMessage id="noMembers" defaultMessage="Liikmesriikide arv:"/></label><span>27</span></li>
              <li><label><FormattedMessage id="holiday" defaultMessage="Tähtpäev:"/></label><span><FormattedMessage id="europeanDay" defaultMessage="9.mai, Euroopa päev"/></span></li>
            </ul>
            <a href="https://estonia.representation.ec.europa.eu/events/euroopa-keeltepaev-2022-2022-08-26_et" className="btn white" target="_blank"
               rel="noopener noreferrer"><FormattedMessage id="eventsBtn" defaultMessage="üritused"/></a>
          </div>
          <div className="game-text">
          <FormattedMessage id="gameText" defaultMessage=" <h1>Õpi tundma Euroopa keeli!</h1>

<p>
  <strong>26. septembril</strong> tähistatakse Euroopas keeltepäeva, millega soovitakse tähtsustada Euroopas
  kõneldavaid keeli ning innustada inimesi keeli õppima. Sel puhul kutsume Sind taas mängima Minu Euroopa
  mängu, mis seekord on pühendatud Euroopa keeltele. Pane oma teadmised proovile üksi või koos klassiga!
</p>

<p>
  Mängu saab mängida 7. septembrist kuni 26. septembrini. Auhinnad loosime välja nädala jooksul pärast mängu
  lõppu. Peaauhinnad on <strong>tahvelarvuti</strong> ja <strong>500-eurone Estraveli kinkekaart
klassiekskursiooni korraldamiseks</strong>, lisaks loosime välja väiksemaid auhindu.
</p>" values={{
  p: content => <p>{content}</p>,
  strong: content => <strong>{content}</strong>,
  h1: content => <h1>{content}</h1>
}}/>
          </div>
        </div>

      </div>
      <img src={LogoEU} className="eu_logo" alt="EU Logo" />
    </div>
  );
};

export default FrontPage;
