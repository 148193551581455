import React, { useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl'
import axios from 'axios';
import IconClose from '../assets/img/icons/icon_close.svg';
import useGame from '../hooks/useGame';

const RulesPage = () => {

    const { hideFeedbackPage } = useGame();

    const { REACT_APP_API_URL, REACT_APP_ENVIRONMENT } = process.env;
    const base = window.location.href.split('?')[0];
    const API_URL = REACT_APP_ENVIRONMENT === 'production' ? `${base}wp/?rest_route=/game` : REACT_APP_API_URL;

    const [rating, setRating] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [submittedForm, setSubmittedForm] = useState(false);
    const [error, setError] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const feedbackField = useRef(null);

    const intl = useIntl();
    const ratingOptions = [
        intl.formatMessage({"id":"liked", "defaultMessage": "Meeldis väga"}),
        intl.formatMessage({"id":"soSo", "defaultMessage": "Nii ja naa"}),
        intl.formatMessage({"id":"didntLike", "defaultMessage": "Eriti ei meeldinud"}),
        intl.formatMessage({"id":"didntLikeAtAll", "defaultMessage": "Üldse ei meeldinud"}),
    ];

    const submitForm = async (e) => {
        e.preventDefault();

        const payload = {
            rating,
            feedback
        };

        try {
            const response = await axios.post(`${API_URL}/feedback`, payload);

            if (response.data) {

                setRating(false);
                setFeedback('');
                setSubmittedForm(true);

                // stopGame();
            }
        } catch (error) {
            setError(true);

            if (error.response && error.response.data) {
                setErrorField(error.response.data.field);
            }
        }
    };
    // render output
    return (
        <div className="feedback-form">
            <div className="inner">
                {!submittedForm && (
                  <>
                <h1><FormattedMessage id="howDidYouLike" defaultMessage="Kuidas Sulle mäng meeldis?"/></h1>

                <form autoComplete="off">
                    <div className="rating">
                    { ratingOptions.map((option, i) => <label htmlFor={"rating-"+i} className="custom-check">
                            <input type="radio" id={"rating-"+i} name="rating" value={option} checked={rating == option}
                                onChange={(e) => setRating(e.target.value)} />
                            <span className="custom-button" />
                            <span className="text">{option}</span>
                        </label>) }
                    </div>
                    <div className="feedback-box">
                        <FormattedMessage id="suggestions" defaultMessage="Ettepanekud"/>
                              <textarea name="feedback" ref={feedbackField} onChange={(e) => setFeedback(e.target.value)}>{feedback}</textarea>
                    </div>

                    <div className="submit-container">
                        <button className="btn submit" type="submit" disabled={!rating}
                            onClick={(e) => submitForm(e)}><p className="error-notice"><FormattedMessage id="send" defaultMessage="Saada"/></p>
                          </button>
                        {error && (<p className="error-notice"><FormattedMessage id="markOpinion" defaultMessage="Palun märgi oma arvamus"/></p>)}
                    </div>
                </form>
                  </>
                )}
                {submittedForm && (
                    <h2><FormattedMessage id="thanksFeedback" defaultMessage="Täname tagasiside eest!"/></h2>
                )}
            </div>
            <div className="close">
                <span onClick={() => hideFeedbackPage()}><FormattedMessage id="close" defaultMessage="Sulge"/></span>
                <img src={IconClose} className="close-icon" onClick={() => hideFeedbackPage()} alt="" />
            </div>
        </div>
    );
};

export default RulesPage;
