import React from 'react';

const NetherlandsSVG = () => {

  const css = `.st2-9{fill:url(#SVGID_1_9);}
.st3-9{fill:url(#SVGID_2_9);}
.st4-9{fill:url(#SVGID_3_9);}
.st5-9{fill:url(#SVGID_4_9);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M695.7,425.4l5.5-0.1l5.1-0.3l4.3-0.5l4.4-0.7l5.9-1.5l5.6-1.7l4.1-1.9l3.1-2.2l3.4-3.3l1.9-2l1.5-0.8l3.3-1
							l3.8-1.2l4.1-1.3l3.4-1.3l2.3-1.7l2.4-2.1l2.4-2.4c0,0,1.9-0.8,2.1-0.8s4.1-0.7,4.1-0.7l2.6-0.1l3.1,0.7l1.3,1.5l0.3,1.9l1.8,1.7
							l2.1,1.7l4,0.6l3,0.1l3-0.7l1-1.2l0.4-2.7l0.1-1.3l1-1.5l2.8-1.9l3.3-1.9l3.5-0.8l5.1-0.5l3.5-0.1l8.6,1.1l6,1.1l5,0.8l5.4,0.3
							l2.7,0.6l1.6,1.2l2.1,1.2l2.2,0.4h3.1l3.1-1.1l-0.7,2.6l-1.2,3.3l-1.3,2.3l-2.1,2l-2,1.1l-3.4,1.3l-3.1,0.6l-4.4,0.7l-3.3,0.3
							l-4.5,0.4l-1.9,0.5l-0.6,1.2l0.4,1.6l2.4,2.7l2.1,3.3l0.3,1.7l-0.8,1.9l-1.7,1l-2.2,0.2l-3.3,1l-1.9,1.3l-1.9,2.2l-1.8,1.6l-2.4,0.7
							h-2.1L801,434l-4.3-0.3l-2.2-0.5l-1.6,0.1l-1.3,2.1l-1,2.7l-2.2,2.2l-2.4,2.3l-6.6,6.7l-4.5,4.8l-2.8-2.9l-4.2-4.1l-5.9-5.4
							l-5.7-4.2l-4.2-4.7l-3.3-2.3l-5.7-1l-3.7-0.1l-4.7,0.6l-7,0.7L719,431h-8.2l-5.4-0.3l-5.1-1l-3-2.2L695.7,425.4z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="783.9" cy="420.9" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_9" gradientUnits="userSpaceOnUse" x1="2356.5093" y1="-872.9361" x2="2399.8123"
                          y2="-872.9361" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{ stopColor: '#FFCD00' }} />
            <stop offset="0.25" style={{ stopColor: '#FFE100' }} />
            <stop offset="0.2883" style={{ stopColor: '#FFE204' }} />
            <stop offset="0.3286" style={{ stopColor: '#FFE311' }} />
            <stop offset="0.3697" style={{ stopColor: '#FFE626' }} />
            <stop offset="0.4115" style={{ stopColor: '#FFE943' }} />
            <stop offset="0.4537" style={{ stopColor: '#FFEE69' }} />
            <stop offset="0.4963" style={{ stopColor: '#FFF398' }} />
            <stop offset="0.5384" style={{ stopColor: '#FFFACD' }} />
            <stop offset="0.5647" style={{ stopColor: '#FFFEF3' }} />
            <stop offset="0.692" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.8818" style={{ stopColor: '#DDB918' }} />
            <stop offset="1" style={{ stopColor: '#CB9925' }} />
          </linearGradient>
          <path className="st2-9" d="M762.8,420.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C761.9,416.5,761.5,420.2,762.8,420.9z" />

          <linearGradient id="SVGID_2_9" gradientUnits="userSpaceOnUse" x1="2377.8" y1="-883.7603" x2="2378.1306"
                          y2="-840.1536" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{ stopColor: '#D97428' }} />
            <stop offset="0.2913" style={{ stopColor: '#E99517' }} />
            <stop offset="0.7079" style={{ stopColor: '#FFC000' }} />
            <stop offset="0.7739" style={{ stopColor: '#FFC600' }} />
            <stop offset="0.8613" style={{ stopColor: '#FFD700' }} />
            <stop offset="0.9602" style={{ stopColor: '#FFF200' }} />
            <stop offset="1" style={{ stopColor: '#FFFF00' }} />
          </linearGradient>
          <path className="st3-9" d="M762.8,420.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C761.9,416.5,761.5,420.2,762.8,420.9z" />

          <linearGradient id="SVGID_3_9" gradientUnits="userSpaceOnUse" x1="2356.5093" y1="-840.0923" x2="2399.8472"
                          y2="-840.0923" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="0" style={{ stopColor: '#FFC600' }} />
            <stop offset="6.884691e-02" style={{ stopColor: '#FFC704' }} />
            <stop offset="0.1411" style={{ stopColor: '#FFCA11' }} />
            <stop offset="0.2149" style={{ stopColor: '#FFCF26' }} />
            <stop offset="0.2898" style={{ stopColor: '#FFD643' }} />
            <stop offset="0.3656" style={{ stopColor: '#FFDE69' }} />
            <stop offset="0.442" style={{ stopColor: '#FFE998' }} />
            <stop offset="0.5176" style={{ stopColor: '#FFF5CD' }} />
            <stop offset="0.5647" style={{ stopColor: '#FFFEF3' }} />
            <stop offset="0.7232" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.7269" style={{ stopColor: '#FEF201' }} />
            <stop offset="0.9143" style={{ stopColor: '#D7A120' }} />
            <stop offset="1" style={{ stopColor: '#C8812C' }} />
          </linearGradient>
          <path className="st4-9" d="M762.8,420.9c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C761.9,416.5,761.5,420.2,762.8,420.9z" />

          <radialGradient id="SVGID_4_9" cx="2378.05" cy="-892.3226" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="2.643507e-02" style={{ stopColor: '#FFFEEE' }} />
            <stop offset="7.899498e-02" style={{ stopColor: '#FFFCC1' }} />
            <stop offset="0.1528" style={{ stopColor: '#FFF978' }} />
            <stop offset="0.2441" style={{ stopColor: '#FFF516' }} />
            <stop offset="0.2634" style={{ stopColor: '#FFF400' }} />
            <stop offset="0.5731" style={{ stopColor: '#DFA51F' }} />
            <stop offset="0.7187" style={{ stopColor: '#D2852B' }} />
            <stop offset="0.7874" style={{ stopColor: '#D4882C' }} />
            <stop offset="0.8453" style={{ stopColor: '#D8932E' }} />
            <stop offset="0.8992" style={{ stopColor: '#E1A431' }} />
            <stop offset="0.9504" style={{ stopColor: '#ECBD35' }} />
            <stop offset="1" style={{ stopColor: '#FBDC3B' }} />
          </radialGradient>
          <circle className="st5-9" cx="783.3" cy="362.2" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M791.1,340.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V309c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C806.3,333.7,799.5,340.5,791.1,340.5z" />
          <path className="st7" d="M791.1,338.5h-16.3c-8.4,0-15.2-6.8-15.2-15.2V307c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C806.3,331.7,799.5,338.5,791.1,338.5z" />
          <path className="st7" d="M789.8,339.3l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C791.4,335,791.4,337.7,789.8,339.3z" />
          <path className="st6" d="M784.6,330.4l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C783.9,330.9,784.3,330.7,784.6,330.4z" />
        </g>
      </g>

    </>
  );
};

export default NetherlandsSVG;
