import React from 'react';

const FranceSVG = () => {

  const css = `.st2-24{fill:url(#SVGID_1_24);}
.st3-24{fill:url(#SVGID_2_24);}
.st4-24{fill:url(#SVGID_3_24);}
.st5-24{fill:url(#SVGID_4_24);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M406.7,446.7c0-0.3-0.1-3.6-0.4-3.8c-0.3-0.2,0.6-1.7,0.6-1.7l3.6-1.7l5.3-0.7l7.9,0.1l5.1,0.8l3.7,1.2l2.2,1.4
							l3.7,0.4h13.3l6.8,0.3l2.7,1.2l2.8,2.9l3,3.7l1.9,2.7l1.6,1l5.4-0.6l4.7-0.2l2.6,0.7l5,2.4l4.7,2.3l3.9,1.7l2.8-0.3l3.4-0.7l2.8-1.4
							l2.4-2.3l1.9-1.9l1.9-3l1-3.2l0.1-3.4l-0.3-2.9l-1.6-2.6l-0.2-2.9l1.6-1.8l2.4-1.2l3.8-1l5.2-0.3l5.2,0.2l1.9,0.7l1.7,0.7l0.9,1.4
							l0.2,2.1l0.3,2.2l0.9,1.4l2.7,2.6l6.3,4l7,2.9l7.8,2l5,0.3l3.7-0.7l3.3-1.7l6-4.1l3.6-2.3l4.1-1.3l2.8,0.1l6.8,0.8l10.3,1.7
							l10.3,0.2l6.6-0.1l4.2-0.6l3.7-1.1l2.3-1.8l4.8-6.8l2.9-3.7l3.7-2.9l5.1-2.7l3.4-0.8l6.4-0.3l6.2,0.7l5.2,0.7l2.3,0.7l0.1,1.7
							l1.1,1.3l-1.1,2.9l0.7,2.4l2.4,2.6l2,0.9l2.8-0.4l2.3,0.9l3.9,4.7l5.9,5.3l6.1,3.7l5,4.1l0.8,2.1l-1.4,2.3l0.2,2.8l2.1,2l1.7,0.7
							l5.1-0.6l12.1-3l0.9,1.1L724,466l-1.1,2.9l-0.8,2.6l1,2.3l2,1.3l5,1.8l2.4,3.3l2.9,4.6l2.2,1.6l3.1,0.2l2.2-0.8l2.6,0.8l4.1,1.3
							l7.3,2.3l5.9,1.2l2.8,1.4l2.2,2.2l1.4,2.8l2.4,2l3.2,2.7l4.1,1.9l4.9,1.2l3.8,1.3l4,1.4l3.1,2.4l2.9,1.6l4.8,0.8l2.2,0.9l1.1,1.3
							l0.3,2.3l-1.6,1.2l-3.9,0.7l-5.2,1.7l-7.3,2.9l-4.4,3.2l-6.7,5.9l-4.6,5.6l-5.1,5.2l-3.3,2.1l-1.2,0.6l-2.6-0.1l-2.2-0.7H753
							l-3.9,0.3l-3.2,1l-3.4,1.8l-3.2,2l-1.8,1.7l-4.8,1.3l-6,1.6l-8.1,2.3l-7.1,2.6l-4.1,3l-4.2,3.8l-1.9,2.3l-0.3,1.6l1,1l1.7-0.1
							l3.7-1.8l4.4-1l3.1-0.2l3.7,0.7l2.9,1.6l0.3,1.7l-0.2,0.9l-1.3,1.6l0.3,2.4l1.2,1.6l1.2,1.9v1.3l-2.1,2.1L718,588l-2.3,2.9l-0.3,2.2
							l0.6,1.4l1.3,2.1v1.6l-2.3,2.2l-3.8,1.7l-4.9,0.9H701l-2.9-1l-2.2,1l-1.1,2.2l1.1,3.2l3.4,4l1.9,2.7l-0.2,2.4l-2.4,1.4l-4.7,2.7
							l-1.4,3v2l2.2,2.7l2.2,1.9l3.2,1.1l10.2,2.2l2,1.8l-1.7,2.2l-4.4,2.8l-3.3,2.4l-2.3,0.7l-10.7,0.8l-6.7,1.4l-7.8,2.2l-9.6,2.6
							l-5.9,1.1l-4.8,0.8l-5.6,0.6l-4.4-0.3l-3-0.8l-3.2-1.3l-5.3-3.3l-8.7-5.8L614,637l-11-5l-10.4-3.3l-8.3-1.8l-9.8-0.8h-7.2l-5.7,0.3
							l-5.8,0.9l-6.1,1.7l-3.8,2.9l-2.8,2.7l-1.9,2.6l-2.2,4.3l-1.3,4.4l-1,2l-18.6-4.9l-25.2-8.2l-14.8-5.9l-4.7-2.7l-2-3.8l-3.1-2.3
							l-2.2-2.6l-3.3-0.9l-1.4,0.7l-2.3,0.9l-2.3,1.6l-3.3,1l-3.7,0.2l-4.3,0.1l-4-1.6l-4.7-2.9l-12.3-9l-6.4-4.6L399,589.1l3.2-1l6.8-2.6
							l8.2-5.8l12.8-10.1l8.7-8.9l4-5l5.1-5.9l11.1-8.6l6.1-6.4l0.6-2.1c0,0,0.6-3.6,1.2-4c0.7-0.4,5-4.2,5-4.2l2-3.7l-0.7-2.4l-1.7-1.6
							l-6.6-4.7L458,506l-2.6-4.2l-0.3-3.6l2.1-3.6l3.3-3.9l0.4-2.3l-2-1.9l-3.6-3.1l-1.6-1.6l-4.6-1l-6.4-1.4l-4.4-2.7l-5.8-4.3
							c0,0-4.7-1.9-5-1.9s-13.3-5.3-13.7-5.7s-6.8-2.9-6.8-2.9l-5.1-3.9l-1.7-2.7l1.3-2.1l2.3-3.3l2-2.3L406.7,446.7z"/>
        <polygon className="st0" points="742.2,678.9 735.7,681.8 728.9,683.9 723,685.1 717,686.3 711.7,691 709.3,696 708.3,700.6
							708.3,704.6 708.8,708.3 709.7,712 711.1,714.6 713.2,716.6 715.7,717.9 718.4,718.2 721.3,717.2 725.1,715.3 728.7,712.3
							733.6,707.1 737.8,701.8 742.9,694 747.9,684.8 749.3,681.7 749.8,678.8 748.8,677.8 747,677.3 745.1,677.8 "/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="570.7" cy="600.2" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_24" gradientUnits="userSpaceOnUse" x1="2570.4263" y1="-694.5172" x2="2613.7292" y2="-694.5172" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-24" d="M549.6,600.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C548.7,595.8,548.3,599.5,549.6,600.2z"/>

          <linearGradient id="SVGID_2_24" gradientUnits="userSpaceOnUse" x1="2591.7168" y1="-705.3413" x2="2592.0476" y2="-661.7346" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-24" d="M549.6,600.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C548.7,595.8,548.3,599.5,549.6,600.2z"/>

          <linearGradient id="SVGID_3_24" gradientUnits="userSpaceOnUse" x1="2570.4263" y1="-661.6733" x2="2613.7642" y2="-661.6733" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-24" d="M549.6,600.2c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C548.7,595.8,548.3,599.5,549.6,600.2z"/>

          <radialGradient id="SVGID_4_24" cx="2591.967" cy="-713.9036" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-24" cx="570.1" cy="541.5" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M577.9,519.8h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C593.1,513,586.3,519.8,577.9,519.8z"/>
          <path className="st7" d="M577.9,517.8h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C593.1,511,586.3,517.8,577.9,517.8z"/>
          <path className="st7" d="M576.6,518.6l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C578.2,514.3,578.2,517,576.6,518.6z"/>
          <path className="st6" d="M571.4,509.7l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C570.7,510.2,571.1,510,571.4,509.7z"/>
        </g>
      </g>
    </>
  );
};

export default FranceSVG;
