import React from 'react';
import Logo from '../assets/img/logo.png';

const Header = () => {

  // render output
  return (
    <header id="header">
      <div className="container">
        <div className="logo-container">
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </header>
  );
};

export default Header;
