import React from 'react';

const SwedenSVG = () => {

  const css = `.st2-2{fill:url(#SVGID_1_2);}
               .st3-2{fill:url(#SVGID_2_2);}
               .st4-2{fill:url(#SVGID_3_2);}
               .st5-2{fill:url(#SVGID_4_2);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M1053.2,304.4l-1.1-3.6l0.2-4l1.2-3.6l2.3-2.8l1-1.3l3.7,1.7l3.7,2.3h2.1l2.2-1.3l8.4-9.8l8.2-7.4l2.4-2.2
							l1.2-2.9l1.4-1.1l2.8,0.4l6.8,1.8l4.3,0.2h3.6l2.2-0.7l3.6-2.3l3-3.9l2.1-3.3l0.2-2.6l-1.1-2.6l-2.6-2.4l-0.4-2.3l1.1-2.6l2.3-1h1.7
							l4,0.7l4.2-0.2l3.9-1.1l2.3-2l2.3-3.3l-0.4-2.4l-2.3-2.9l-6.2-3.4l-3.8-3.2l-1-1.9l1.1-2.1l4.1-3l5.3-4.8l0.7-2.8l-1-3l-0.2-3.2
							l1.3-2.4l3.4-2.9l4.2-3.8c0,0,0.6-1.3,0.7-1.7c0.1-0.3,0.1-5.1,0.1-5.1l1.9-3.1l3.6-3.2l5.2-3l4.6-2.1l7.1-2l10.2-1.6l5.7-0.3
							l7.1,0.8l5.2,2l2.9,2.2l2.8,0.8l4.9-0.6l3-0.8l3.8-2.2l2.6-2.8l0.6-2l-1.1-1.4l-3.2-1.2l-3.2-1.6l-1.7-1.7l-0.2-1.7l1.3-1.7l4.4-2.1
							l6.9-2.7l9.6-3.8l4.1-2.1l4-2.8l2.8-2.6l2.8-3.8l2.1-3.9l1.6-3.3l2-1.8l3.3-0.6l4.6,0.3l7.7,1.2h7l3.9-0.9l2.6-1.4l1.9-2.6l1.2-2.1
							l1.8-1.1l3.2-1l3.1-1.3l5.7-3.1l3.2-0.8l4.3-1.7l5.4-0.6l3.4-1.6l1.6-1.6l0.2-1.7l-0.6-1.4l1.7-1.8l3.7-1.3l3.2-1l3.7-2.1l3-2.7
							l4.3-2.7l3.2-0.9l4.1-0.3l5.6,0.2l4.8,0.1l10,1.8l8.2,0.7l3.9-0.2l1.4-0.7l1.6-3.2l1-3.8l2-3.4l1.8-1.1l1.8-0.4l2.9,0.3l3.1,2.3
							l2.7,1.8l3.7,0.7l8.2,0.4l6.2,1.1l3,0.7l3.1,2.8l2.4,2.1l3.7,1.6l4.2,0.7l4.6-1l3.1-2.3l3.1-5l4.4-4.2l3.4-1.6h3l4.2,1.2l3.9,1
							l2,1.5l3.1,2.2l10.3,7.4l7.3,4.9l6.5,3.3l14.8,6.2l10.7,3.9l4.9,3l1.2,2.8l-0.6,3l-4.3,4l-1.9,3.7l0.9,2.7l2.7,3.9l0.4,2.8l-3,4.6
							l0.1,2.2l2.7,2.4l2.4,2.5l-0.1,4.9l-3.6,3.3l-3.3,1.9l-3-0.6l-2.4,0.4l-0.3,2.4l2.5,3.7l2.7,2.2l0.6,3.9l-0.1,4.6l-2.7-0.7l-6.2-1.4
							l-5.1-0.6l-7.7-0.7l-5.1-0.7l-5.4-1.2l-5.1-1.3l-3.8-1.2h-4.8l-5.1,2l-4.8,1.6l-6.4,1.1l-5.1,0.3l-4,0.2l-1.7,0.1l-1.7,1.7l-1.9,2.9
							l-3.2,2.8l-2.3,1.3l-3,0.8l-5.4,1l-2.1,1.8l-0.1,2.4l1.6,2.3l2.3,3.7v1.9l-0.8,1.6l-3.9,2l-6.3,1.8l-11.3,2.3l-9.2,1.4l-20.8,2.3
							l-15.6,1.4l-17.9,1.6l-3.9,0.4l-4.8,1.4l-3.6,2.2l-4.1,2.7l-3.2,1.2l-3.6,1l-4,0.2l-5.4,0.2l-3.1,1l-2.6,2.2l-3.6,0.7l-4.4-0.1
							l-3.8,1l-2.3,2.1c0,0-3,4.8-3.2,5.1s-2.1,3.2-2.6,3.4c-0.4,0.2-3.9,2.6-3.9,2.6l-6.7,3.6l-4.8,3.1l-2.2,2.4l-0.7,5l-2.1,6.4
							l-2.4,4.4c0,0-1.6,2.6-1.6,2.9s0.1,1.7,0.1,1.7l4.1,1.9l5.1,0.2l5.8,1.4l8.8,4.9l5.2,5.6l7.3,8.1l2.3,3.3l0.3,2.1l-2.2,1.9l-3.2,0.9
							l-7.7,2.3l-8.7,2.6l-2.2,2.2l-1.9,2.9l-4.3,2.7l-7,2.4l-7.4,0.8l-12.8,0.8h-7.8l-6.4,0.8l-4,1.3l-3,2.4l-3.7,4.9l-8,10l-6.7,8.8
							l-6.7,5.1l-5.6,5l-2,3.1l1.2,1.6h3.8l9.2-3.9l6.2-1.3l1.2,0.8c0,0-0.2,1.9-0.7,2.1c-0.4,0.2-5.3,3.2-5.3,3.2l-11.1,7.1l-8.1,4.6
							l-2.7,0.4l-1.6-1.4l-2.8-3.3l-2-1.9l-1.8-0.4l-1.9,0.8l-2.9,2.7l-3.8,3.1l-3.9,1.9l-4.1,0.4l-3.1-0.8l-3.9-1.1l-5.9-3.4l-8.1-2.1
							l-7.7-0.7l-4.3,0.8l-1.7,1.2l-0.9,1l-1.4,0.2l-1.4-0.1l-3.8-0.8l-2,0.4l-1.7,1l-2.7,4.2l-2,3.4l-1.9,2l-2.2,0.8l-4.6-0.3l-7.4-1.2
							l-5.8-0.4l-6.4,0.3l-5-0.9l-1.7-1l-0.7-1.4l0.3-2.8l2.6-3.7l4.8-6.7l0.4-1.9l-2.1-2.4l-2.7-2l-1.2-2.2l1.4-2l2.2-2.3l3.4-2.9
							c0,0,1-1.7,1-2s-0.6-2.8-0.6-2.8l-1.8-2.7l-3.4-4.2l-1.6-3.7l0.1-5.7l-1.2-4.1l-1.9-3.2l-1.2-2.2l0.2-4.2c0,0,2-3.8,2.1-4.1
							c0.1-0.3,1.1-3.1,1.1-3.1l-0.8-2.7l-1.7-2.4l-3.2-3.4L1053.2,304.4z" />
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1190.3" cy="263.8" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_2" gradientUnits="userSpaceOnUse" x1="1949.4274" y1="-1028.3777" x2="1992.7303"
                          y2="-1028.3777"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>
          <path className="st2-2" d="M1169.2,263.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1168.3,259.4,1167.9,263.1,1169.2,263.8z" />

          <linearGradient id="SVGID_2_2" gradientUnits="userSpaceOnUse" x1="1970.718" y1="-1039.2019" x2="1971.0487"
                          y2="-995.5952" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3-2" d="M1169.2,263.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1168.3,259.4,1167.9,263.1,1169.2,263.8z" />

          <linearGradient id="SVGID_3_2" gradientUnits="userSpaceOnUse" x1="1949.4274" y1="-995.5339" x2="1992.7653"
                          y2="-995.5339" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4-2" d="M1169.2,263.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1168.3,259.4,1167.9,263.1,1169.2,263.8z" />

          <radialGradient id="SVGID_4_2" cx="1970.968" cy="-1047.7642" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5-2" cx="1189.7" cy="205.1" r="14.3" />
        </g>
        <g className="arrow">
          <path className="st6" d="M1197.5,183.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1212.7,176.6,1205.9,183.4,1197.5,183.4z" />
          <path className="st7" d="M1197.5,181.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1212.7,174.6,1205.9,181.4,1197.5,181.4z" />
          <path className="st7" d="M1196.2,182.2l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1197.8,177.9,1197.8,180.6,1196.2,182.2z" />
          <path className="st6" d="M1191,173.3l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1190.3,173.8,1190.7,173.6,1191,173.3z" />
        </g>
      </g>
    </>
  );
};

export default SwedenSVG;
