import React from 'react';

const EstoniaSVG = () => {

  const css = `.st2{fill:url(#SVGID_1_);}
               .st3{fill:url(#SVGID_2_);}
               .st4{fill:url(#SVGID_3_);}
               .st5{fill:url(#SVGID_4_);}`

  // render output
  return (
    <>
      <style type="text/css">{css}</style>

      <g className="country">
        <path className="st0" d="M1363.9,361.4l5.3-1.2l3.4-0.2l5.6,0.1l5.7,0.5l6.1,1.6l4,1.4l1,1.5l-0.4,0.9c0,0-1.9,1.3-2.1,1.3
							s-7.6,1.9-7.6,1.9l-10.4,2.2l-10,1l-8.9-0.4l-1.5-1.3l-0.3-1.9l1.3-1.9c0,0,2-1.9,2.1-2.1s2.7-1.6,2.7-1.6L1363.9,361.4z" />
        <path className="st0" d="M1406.8,358.6l-1.4-2.7l-0.4-1.4l1-1.3l1.9-0.7l3.5-0.1l3.2-0.1c0,0,5-0.4,5.3-0.4c0.2,0,7.3-1.4,7.3-1.4
							l3.1-0.9l4.3-0.6l7-0.1l6.8,0.8l7,0.9l8.9,1.1l8.3,1l13.5,1.3l8.3,0.9l6.4,1.9l12.7,4.1l5.9,2.1l5.3,1.2l6.7,1.9l5.6,1.3l7.3,0.9
							l5.6,0.3l2.5,0.1l1.5,1l0.1,2.2l-0.9,1.9l-3.1,1.8l-3.7,1.3l-5.8,2.7l-7.4,3l-4.3,2.2l-1.8,1.9v3.7l-1,5.2l-0.6,4.1l0.4,1.6l3.3,4
							l0.9,2.1l-0.6,1.9l-1.3,0.6l-3,0.4h-4.9l-4.1,1l-0.7,1.8l-0.9,2.1l-7.1-1.3l-3.7-1.2l-8.4-1.6l-5.9-1.5l-6.4-0.9l-2.1-1l-4.3-3.1
							l-9-7.1l-7.1-4.6l-4.4-1.8l-5.6-1.2l-7.7-1.3l-8.1-0.3l-4-0.9l-2.1-2.2l1.8-1l2.7-1.6l-0.1-2.1l-2.1-1.9l-3-1.3l-3.1-0.1l-4.6-0.4
							l-3.3-0.4l-2.5-1.2l-3.6-2.8l-1.6-2.4l-2.1-3l-0.1-2.4l1-2.4l-0.1-2.1L1406.8,358.6z" />
      </g>

      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1479.3" cy="382.8" rx="22.4" ry="5.6" />

          <linearGradient id="SVGID_1_1" gradientUnits="userSpaceOnUse" x1="118.1322" y1="861.1825" x2="161.4352"
                          y2="861.1825"
                          gradientTransform="matrix(-1 4.101219e-03 -4.101219e-03 -1 1351.5686 1340.1105)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1660.9203" y1="-908.1945" x2="1704.2233"
                          y2="-908.1945" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="6.696430e-03" style={{stopColor: "#FFCD00"}} />
            <stop offset="0.25" style={{stopColor: "#FFE100"}} />
            <stop offset="0.2883" style={{stopColor: "#FFE204"}} />
            <stop offset="0.3286" style={{stopColor: "#FFE311"}} />
            <stop offset="0.3697" style={{stopColor: "#FFE626"}} />
            <stop offset="0.4115" style={{stopColor: "#FFE943"}} />
            <stop offset="0.4537" style={{stopColor: "#FFEE69"}} />
            <stop offset="0.4963" style={{stopColor: "#FFF398"}} />
            <stop offset="0.5384" style={{stopColor: "#FFFACD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.692" style={{stopColor: "#FFF400"}} />
            <stop offset="0.8818" style={{stopColor: "#DDB918"}} />
            <stop offset="1" style={{stopColor: "#CB9925"}} />
          </linearGradient>

          <path className="st2" d="M1458.2,382.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1457.3,378.4,1456.9,382.1,1458.2,382.8z" />

          <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1682.2109" y1="-919.0187" x2="1682.5415"
                          y2="-875.412" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#D97428"}} />
            <stop offset="0.2913" style={{stopColor: "#E99517"}} />
            <stop offset="0.7079" style={{stopColor: "#FFC000"}} />
            <stop offset="0.7739" style={{stopColor: "#FFC600"}} />
            <stop offset="0.8613" style={{stopColor: "#FFD700"}} />
            <stop offset="0.9602" style={{stopColor: "#FFF200"}} />
            <stop offset="1" style={{stopColor: "#FFFF00"}} />
          </linearGradient>
          <path className="st3" d="M1458.2,382.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2s-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1457.3,378.4,1456.9,382.1,1458.2,382.8z" />

          <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="1660.9203" y1="-875.3506" x2="1704.2582"
                          y2="-875.3506" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="0" style={{stopColor: "#FFC600"}} />
            <stop offset="6.884691e-02" style={{stopColor: "#FFC704"}} />
            <stop offset="0.1411" style={{stopColor: "#FFCA11"}} />
            <stop offset="0.2149" style={{stopColor: "#FFCF26"}} />
            <stop offset="0.2898" style={{stopColor: "#FFD643"}} />
            <stop offset="0.3656" style={{stopColor: "#FFDE69"}} />
            <stop offset="0.442" style={{stopColor: "#FFE998"}} />
            <stop offset="0.5176" style={{stopColor: "#FFF5CD"}} />
            <stop offset="0.5647" style={{stopColor: "#FFFEF3"}} />
            <stop offset="0.7232" style={{stopColor: "#FFF400"}} />
            <stop offset="0.7269" style={{stopColor: "#FEF201"}} />
            <stop offset="0.9143" style={{stopColor: "#D7A120"}} />
            <stop offset="1" style={{stopColor: "#C8812C"}} />
          </linearGradient>
          <path className="st4" d="M1458.2,382.8c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1457.3,378.4,1456.9,382.1,1458.2,382.8z" />

          <radialGradient id="SVGID_4_" cx="1682.4609" cy="-927.5809" r="21.3446"
                          gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" style={{stopColor: "#FFFFFF"}} />
            <stop offset="2.643507e-02" style={{stopColor: "#FFFEEE"}} />
            <stop offset="7.899498e-02" style={{stopColor: "#FFFCC1"}} />
            <stop offset="0.1528" style={{stopColor: "#FFF978"}} />
            <stop offset="0.2441" style={{stopColor: "#FFF516"}} />
            <stop offset="0.2634" style={{stopColor: "#FFF400"}} />
            <stop offset="0.5731" style={{stopColor: "#DFA51F"}} />
            <stop offset="0.7187" style={{stopColor: "#D2852B"}} />
            <stop offset="0.7874" style={{stopColor: "#D4882C"}} />
            <stop offset="0.8453" style={{stopColor: "#D8932E"}} />
            <stop offset="0.8992" style={{stopColor: "#E1A431"}} />
            <stop offset="0.9504" style={{stopColor: "#ECBD35"}} />
            <stop offset="1" style={{stopColor: "#FBDC3B"}} />
          </radialGradient>
          <circle className="st5" cx="1478.7" cy="324.1" r="14.3" />
        </g>

        <g className="arrow">
          <path className="st6" d="M1486.5,302.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
							v16.3C1501.7,295.6,1494.9,302.4,1486.5,302.4z" />
          <path className="st7" d="M1486.5,300.4h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
							v16.3C1501.7,293.6,1494.9,300.4,1486.5,300.4z" />
          <path className="st7" d="M1485.2,301.2l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
							C1486.8,296.9,1486.8,299.6,1485.2,301.2z" />
          <path className="st6" d="M1480,292.3l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
							c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
							c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
							c0.3,0.3,0.7,0.5,1.2,0.5C1479.3,292.8,1479.7,292.6,1480,292.3z" />
        </g>

      </g>
    </>
  );
};

export default EstoniaSVG;
