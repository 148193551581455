import React from 'react';

const GreeceSVG = () => {

  const css = `.st2-13{fill:url(#SVGID_1_13);}
.st3-13{fill:url(#SVGID_2_13);}
.st4-13{fill:url(#SVGID_3_13);}
.st5-13{fill:url(#SVGID_4_13);}`;

  // render output
  return (
    <>
      <style type="text/css">{css}</style>
      <g className="country">
        <path className="st0" d="M1306.8,843.7l0.1,6l-0.6,4.6l-1.3,2.6l-2.4,1.7l-2.8,1.1l-4.2,1.3l-3,1.8l-1.3,1.4l-2.7,2.7l-3.4,1.3l-5.3,2.3
							l-5.4-5.1l-5.7-4.3l-6.8-3.6l-4.1-1.3l-6.2-1.1l-6.8,0.3l-4,0.4l-5.4,1.9l-4.4,1.1l-1.8,0.2l-1.6-0.7l-2.3-2.3l-3.8-3.7l-4.3-2.6
							l-5.3-1.1l-7.9,0.4l-4.6,0.7l-3.3,2.7l-0.9,2l-0.9,3.2l-0.1,4.1l-0.4,2.4l-0.9,1.8c0,0-1.9,0.2-2.3,0.2s-7-0.9-7-0.9l-6.2-0.7
							l-4.8-1.1l-3.9-1.8l-2.9-2.6l-1.7-2.3l-1.7-3.3l-3.1-1.9l-5.4-1.2l-4.1-0.6l-5.1,1.6l-3.2,1.8l-1.3,2.4l-0.1,2.9
							c0,0,1.8,2.1,1.8,2.4c0,0.3,4.6,7.2,4.6,7.2l7,11.2l4.4,8l0.3,4.1l-1,3.6l-3,2.3l-4-0.1l-6.9-0.2l-3.3,0.1l-2.3,1.1l-0.9,2.1
							l1.2,3.4l3.8,3.4l3.6,3h4l6.7-1.3l5.4-0.3l5.9,1.3l5.6,1.9l4.1,3.3l3.4,3.7l1.4,3.6l-0.4,3.6l-1.8,4.2c0,0-2.7,2.7-2.7,3
							s-2.3,1.8-2.3,1.8l-3.8,0.7l-8.1,0.2h-6.1c0,0-2.6-0.8-2.7-1.1s-6.4-4.1-6.4-4.1l-4.4-2.9l-4.1-1.2l-6.1-0.1l-4.7,0.9l-2.1,1.3
							l-0.4,1.4l0.3,1.4l2.7,1.3l3.4,0.8l5.1,0.9l5.9,3.4l2.4,2.4l0.6,2.6l-0.8,1.6l-1.9,1.6l-3,1.4l-2.6,0.4h-2.1l-2.3-0.6l-2.6-1.8
							l-5.4-2.6l-5.6-2.4l-3-0.3l-1.6,0.9l-1.1,1.8l0.1,3.7l0.8,16.3l-1.2,4.4l-1.9,1.6l-2.8,0.8l-2.3-0.9l-2-2.3l-4-4.4l-7.9-7.8l-4-2.7
							l-2.4-1.1l-2.8-0.3l-4.6-2l-4-2.9l-3.3-2.6l-2-3.2c0,0-0.8-4-0.9-4.3s0.2-3.7,0.2-3.7l1.6-2.3l1.7-2.7l0.9-2.6l-0.8-3.8l-3.1-5
							l-1.9-3.9l0.3-3.3l3.1-3.3l4.9-1.8l5.1-0.4l4.8,1.1l6.6,1.8l9.6,3.7l7.7,2.6l3.6,0.3l1.2-0.6l1.1-1.7l-0.2-2.6l-1.9-2.7l-4.7-4
							l-5.9-3.8l-5.1-2.8l-3.9-1.2h-4.8l-2.7,0.6l-3.2,1.1l-4,0.6h-4.2l-3.4-0.7l-4.8-1.4l-3.1-3.1l-1.3-3.8l0.3-3.1l2-3.3
							c0,0,1.1-2.6,1.2-2.9c0.1-0.3,0-5,0-5l-0.9-4.1l-2.1-4.1l-3.6-3.9l-4.3-3.3l-1.9-1.6l5.6-4.4l5.1-4.4l1.4-0.8l4.9-0.1l3.9-0.6
							l3.2-3.8l3.3-2.3l5.2-2l4.6-2.3c0,0,8.6-4.7,8.8-5c0.2-0.3,3.6-3,3.6-3h3.4l5.1,1.9l8.3,2.1l5.6,1.1l5.8-0.3l3.9-0.8l4-1.4l5.4-1.9
							l1.7,0.6l1.9,1l4.9,0.2l10.1-0.1l10.8-0.7l7.3-1.3l2.7-0.3l2-1.2l2.2-0.8l1.9,1l26.8,5.2l15.1,4.6l5.2,2.2l4.3,2.4l12,3.9l11,4.1
							l14.7,3.1l4.3-0.4l2.4-0.9l1.6-1.2l1.9-3l2.4-0.9h11.3l3.6,0.7L1306.8,843.7z"/>
        <path className="st0" d="M1115.9,1006.8l4.7-1l4-0.2l5.2,0.7c0,0,3,0.7,3.2,1s3.2,2.4,3.2,2.4l7.3,5l6.1,3l7.6,1.8l9,2.4l9.9,2.2
							l10.8,3.3l5.9,1.5l4.7,2.7l5.2,2.4l5.2,0.4l5.9,0.5l3.1,0.8l0.7,1l0.4,1.5l-0.9,0.7l-3,0.5l-5.8,0.4l-7.6-0.1l-8.7-0.1l-9.3-0.5
							l-8.3-0.8l-8.2-1.5c0,0-6.9-1.8-7.1-1.9s-7.1-3.3-7.1-3.3l-15.7-8.1l-13.5-7.2l-9.1-4.6l-1.9-1.3l-0.7-1.4l1.2-1.2l1.7-1H1115.9z"/>
      </g>
      <g className="game-button">
        <g className="the-button">
          <ellipse className="st1" cx="1111.7" cy="859.7" rx="22.4" ry="5.6"/>

          <linearGradient id="SVGID_1_13" gradientUnits="userSpaceOnUse" x1="2030.5367" y1="-432.7844" x2="2073.8396" y2="-432.7844" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="6.696430e-03" style={{stopColor: "#FFCD00"}}/>
            <stop  offset="0.25" style={{stopColor: "#FFE100"}}/>
            <stop  offset="0.2883" style={{stopColor: "#FFE204"}}/>
            <stop  offset="0.3286" style={{stopColor: "#FFE311"}}/>
            <stop  offset="0.3697" style={{stopColor: "#FFE626"}}/>
            <stop  offset="0.4115" style={{stopColor: "#FFE943"}}/>
            <stop  offset="0.4537" style={{stopColor: "#FFEE69"}}/>
            <stop  offset="0.4963" style={{stopColor: "#FFF398"}}/>
            <stop  offset="0.5384" style={{stopColor: "#FFFACD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.692" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.8818" style={{stopColor: "#DDB918"}}/>
            <stop  offset="1" style={{stopColor: "#CB9925"}}/>
          </linearGradient>
          <path className="st2-13" d="M1090.6,859.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1089.7,855.3,1089.3,859,1090.6,859.7z"/>

          <linearGradient id="SVGID_2_13" gradientUnits="userSpaceOnUse" x1="2051.8274" y1="-443.6086" x2="2052.158" y2="-400.0019" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#D97428"}}/>
            <stop  offset="0.2913" style={{stopColor: "#E99517"}}/>
            <stop  offset="0.7079" style={{stopColor: "#FFC000"}}/>
            <stop  offset="0.7739" style={{stopColor: "#FFC600"}}/>
            <stop  offset="0.8613" style={{stopColor: "#FFD700"}}/>
            <stop  offset="0.9602" style={{stopColor: "#FFF200"}}/>
            <stop  offset="1" style={{stopColor: "#FFFF00"}}/>
          </linearGradient>
          <path className="st3-13" d="M1090.6,859.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-0.6-5-12.2-34-14.1-39.2c4.1-2.5,6.8-7.1,6.8-12.2c0-7.9-6.4-14.2-14.3-14.2c-7.9,0-14.2,6.4-14.2,14.3c0,5.2,2.8,9.7,6.9,12.2
								c-1.9,5.2-13.2,34.3-13.8,39.3C1089.7,855.3,1089.3,859,1090.6,859.7z"/>

          <linearGradient id="SVGID_3_13" gradientUnits="userSpaceOnUse" x1="2030.5367" y1="-399.9406" x2="2073.8745" y2="-399.9406" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="0" style={{stopColor: "#FFC600"}}/>
            <stop  offset="6.884691e-02" style={{stopColor: "#FFC704"}}/>
            <stop  offset="0.1411" style={{stopColor: "#FFCA11"}}/>
            <stop  offset="0.2149" style={{stopColor: "#FFCF26"}}/>
            <stop  offset="0.2898" style={{stopColor: "#FFD643"}}/>
            <stop  offset="0.3656" style={{stopColor: "#FFDE69"}}/>
            <stop  offset="0.442" style={{stopColor: "#FFE998"}}/>
            <stop  offset="0.5176" style={{stopColor: "#FFF5CD"}}/>
            <stop  offset="0.5647" style={{stopColor: "#FFFEF3"}}/>
            <stop  offset="0.7232" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.7269" style={{stopColor: "#FEF201"}}/>
            <stop  offset="0.9143" style={{stopColor: "#D7A120"}}/>
            <stop  offset="1" style={{stopColor: "#C8812C"}}/>
          </linearGradient>
          <path className="st4-13" d="M1090.6,859.7c5.7,3.3,16.9,3.5,20.8,3.6l0,0l0,0l0,0l0,0c3.9-0.2,15.1-0.5,20.8-3.8c1.3-0.7,0.8-4.4,0.5-7.2
								c-17.1,3.6-25.4,3.8-42.6,0.2C1089.7,855.3,1089.3,859,1090.6,859.7z"/>

          <radialGradient id="SVGID_4_13" cx="2052.0774" cy="-452.1709" r="21.3446" gradientTransform="matrix(-1 4.101219e-03 4.101219e-03 1 3165.1201 1249.3866)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style={{stopColor: "#FFFFFF"}}/>
            <stop  offset="2.643507e-02" style={{stopColor: "#FFFEEE"}}/>
            <stop  offset="7.899498e-02" style={{stopColor: "#FFFCC1"}}/>
            <stop  offset="0.1528" style={{stopColor: "#FFF978"}}/>
            <stop  offset="0.2441" style={{stopColor: "#FFF516"}}/>
            <stop  offset="0.2634" style={{stopColor: "#FFF400"}}/>
            <stop  offset="0.5731" style={{stopColor: "#DFA51F"}}/>
            <stop  offset="0.7187" style={{stopColor: "#D2852B"}}/>
            <stop  offset="0.7874" style={{stopColor: "#D4882C"}}/>
            <stop  offset="0.8453" style={{stopColor: "#D8932E"}}/>
            <stop  offset="0.8992" style={{stopColor: "#E1A431"}}/>
            <stop  offset="0.9504" style={{stopColor: "#ECBD35"}}/>
            <stop  offset="1" style={{stopColor: "#FBDC3B"}}/>
          </radialGradient>
          <circle className="st5-13" cx="1111.1" cy="801" r="14.3"/>
        </g>
        <g className="arrow">
          <path className="st6" d="M1118.9,779.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1134.1,772.5,1127.3,779.3,1118.9,779.3z"/>
          <path className="st7" d="M1118.9,777.3h-16.3c-8.4,0-15.2-6.8-15.2-15.2v-16.3c0-8.4,6.8-15.2,15.2-15.2h16.3c8.4,0,15.2,6.8,15.2,15.2
								v16.3C1134.1,770.5,1127.3,777.3,1118.9,777.3z"/>
          <path className="st7" d="M1117.6,778.1l-3,3c-1.6,1.6-4.2,1.6-5.9,0l-3-3c-1.6-1.6-1.6-4.2,0-5.9l3-3c1.6-1.6,4.2-1.6,5.9,0l3,3
								C1119.2,773.8,1119.2,776.5,1117.6,778.1z"/>
          <path className="st6" d="M1112.4,769.2l10.9-11.3c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-1-1c-0.3-0.3-0.7-0.5-1.2-0.5
								c-0.4,0-0.9,0.2-1.2,0.5l-6.4,6.6v-20.9c0-0.9-0.7-1.7-1.6-1.7h-1.4c-0.9,0-1.7,0.7-1.7,1.7v21l-6.4-6.6
								c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-1,1c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2l10.9,11.3
								c0.3,0.3,0.7,0.5,1.2,0.5C1111.7,769.7,1112.1,769.5,1112.4,769.2z"/>
        </g>
      </g>
    </>
  );
};

export default GreeceSVG;
