import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';

// helpers
import paths from '../helpers/countryPathHelper';
import countryStates from '../helpers/countryState';
import gameStates from '../helpers/gameState';

export const RootContext = React.createContext([{}, p => {
}]);

export default ({ children }) => {
  const { REACT_APP_API_URL } = process.env;

  const [state, setState] = useState({
    gameState: gameStates.NOT_STARTED,
    outOfTime: false,
    accessText: false,
    visited: [],
  });
  const [hasData, setHasData] = useState(false);

  const [{ data }] = useAxios(`${REACT_APP_API_URL}/countries`);


  useEffect(() => {
    if (!hasData && data) {
      // we are going to create our own object with data
      const countries = data.map((row) => {
        return {
          ...row,
          ...paths[row.ID],
          activeState: countryStates.NONE,
        };
      });


      setState((currentState) => ({
        ...currentState,
        countries,
      }));

      // this is needed to prevent endless looping. Must be a better way, but for now it works
      setHasData(true);
    }
    // eslint-disable-next-line
  }, [hasData, data]);

  return (
    <RootContext.Provider value={[state, setState]}>
      {children}
    </RootContext.Provider>
  );
};
